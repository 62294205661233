import AppColors from "../../functions/AppColors";

const styles = {
  button: {
    borderRadius: "0.5rem",
  },
  icon_container: {
    display: "flex",
    justifyContent: "space-between",
    width: "10rem",
    alignItems: "center",
  },
  icon_container_wrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "0.5rem",
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "0.5rem",
    marginBottom: "1rem",
    alignItems: "center",
  },
  pagination_text_wrapper: {
    display: "flex",
    width: "10rem",
    alignItems: "center",
  },
  pagination_text: {
    margin: 0,
  },
  checkbox: {
    accentColor: AppColors.red,
    height: "1.5rem",
  },
};

export default styles;
