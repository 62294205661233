import axios from "axios";
const baseUrl = "https://iotstaging.hellobikee.com/api/v1";
const token = localStorage.getItem("token");

// const token =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijk1Y2I1NGQyLTZjNWUtNDhjNS05OWYwLWMwMjE5MWI4ZDhkNCIsImVtYWlsIjoic2FtdWVsb2x1YmF5b0B5YWhvby5jb20iLCJpYXQiOjE2ODQzMTQ5NjYsImV4cCI6MTY4NDkxOTc2Nn0.V4Fb2FOsS-UrKAi8K8Ywzi2OkXGaOVq0mndoDqtDkB0";

const apiKey = "foSKETSl.v/j7W1MTRkVE7n1gX8ZD6-UaL1TAjs.6";
export const getBikeAPIs = async (path) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}${path}`, {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "x-api-key": "foSKETSl.v/j7W1MTRkVE7n1gX8ZD6-UaL1TAjs.6",
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject({ status: error.code, msg: error.msg });
        return error;
      });
  });
};

export const handleBikeApi = async (route) => {
  try {
    const user = await fetch(`${baseUrl}/${route}`, {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "x-api-key": apiKey,
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
      },
    });

    return await user.json();
  } catch (err) {
    console.error("Error: ", err);
    return err;
  }
};

export const handlePostBikeApi = async (route, payload) => {
  try {
    const user = await fetch(`${baseUrl}/${route}`, {
      method: "POST",
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "x-api-key": apiKey,
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    return await user.json();
  } catch (err) {
    console.error("Error: ", err);
    return err;
  }
};
