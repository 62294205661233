import React from "react";
import { useQueryClient } from "react-query";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";

export const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();

  const menu = {
    users: ["/users", "/new_signups", "/daily_users", "create_users"],
    teams: ["/teams", "add_teams"],
    clients: ["/realtime", "/add_client", "/areas_list", "/create_area"],
    ebikes: [
      "/bike_list",
      "/assign_bikes",
      "/create_bike",
      "/create_parts",
      "/create_bike_setup",
    ],
    bike_solution: [
      "/solution_list",
      "/create_solution",
      "/solution_category",
      "/solution_plan",
    ],

    finance: ["/plans", "/payments", "/coupons", "/setup_coupon"],
  };
  const [current, setCurrent] = React.useState("dashboard");
  const queryClient = useQueryClient();

  const logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    queryClient.clear();
    history.push("/login");
  };

  return (
    <div className="iq-sidebar">
      <div className="iq-sidebar-logo d-flex justify-content-between">
        <Link to="/dashboard">
          <img src="/assets/images/logo.png" className="img-fluid" alt="" />
        </Link>
      </div>
      <div id="sidebar-scrollbar">
        <nav className="iq-sidebar-menu">
          <ul id="iq-sidebar-toggle" className="iq-menu">
            <li className="iq-menu-title">
              <i className="ri-subtract-line"></i>
              <span>MENU</span>
            </li>
            <li
              className={
                menu.clients.includes(location.pathname) ||
                current === "clients"
                  ? "active"
                  : ""
              }
              onClick={() => setCurrent("clients")}
            >
              <a
                href="#partnrgpssettings1"
                className="iq-waves-effect collapsed"
                data-toggle="collapse"
                aria-expanded="false"
              >
                <i className="ri-mail-open-fill"></i>
                <span>Our Clients</span>
                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
              </a>
              <ul
                id="partnrgpssettings1"
                className="iq-submenu collapse"
                data-parent="#iq-sidebar-toggle"
              >
                <li>
                  <Link to="/realtime">
                    <i className="ri-inbox-fill"></i>Ongoing Rides
                  </Link>
                </li>
                <li>
                  <Link to="/add_client">
                    <i className="ri-inbox-fill"></i>Create New Client
                  </Link>
                </li>
                <li>
                  <Link to="/clients">
                    <i className="ri-inbox-fill"></i>Clients
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                menu.ebikes.includes(location.pathname) || current === "ebikes"
                  ? "active"
                  : ""
              }
              onClick={() => setCurrent("ebikes")}
            >
              <a
                href="#partnrgpssettings2"
                className="iq-waves-effect collapsed"
                data-toggle="collapse"
                aria-expanded="false"
              >
                <i className="ri-mail-open-fill"></i>
                <span>e-Bikes</span>
                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
              </a>
              <ul
                id="partnrgpssettings2"
                className="iq-submenu collapse"
                data-parent="#iq-sidebar-toggle"
              >
                <li>
                  <Link to="/create_bike_type">
                    <i className="ri-inbox-fill"></i>Create Bike Type
                  </Link>
                </li>
                <li>
                  <Link to="/bike_list">
                    <i className="ri-inbox-fill"></i>List of Assigned e-Bikes
                  </Link>
                </li>
                <li>
                  <Link to="/assign_bikes">
                    <i className="ri-inbox-fill"></i>Assign e-Bikes to Clients
                  </Link>
                </li>
                <li>
                  <Link to="/create_bike">
                    <i className="ri-inbox-fill"></i>Create new e-bikes
                  </Link>
                </li>
                <li>
                  <Link to="/heat_map" className="iq-waves-effect">
                    <i className="ri-briefcase-4-fill"></i>
                    <span>Heat Map</span>
                  </Link>
                </li>
                <li>
                  <Link to="/create_parts">
                    <i className="ri-inbox-fill"></i>Create e-bikes parts
                  </Link>
                </li>
              </ul>
            </li>

            <li className="iq-menu-title">
              <i className="ri-subtract-line"></i>
              <span>USERS</span>
            </li>
            <li
              className={
                menu.users.includes(location.pathname) || current === "users"
                  ? "active"
                  : ""
              }
              onClick={() => setCurrent("users")}
            >
              <a
                href="#globalSystemSettings"
                className="iq-waves-effect collapsed"
                data-toggle="collapse"
                aria-expanded="false"
              >
                <i className="ri-mail-open-fill"></i>
                <span>Users</span>
                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
              </a>
              <ul
                id="globalSystemSettings"
                className="iq-submenu collapse"
                data-parent="#iq-sidebar-toggle"
              >
                <li>
                  <NavLink to="/create_users">
                    <i className="ri-inbox-fill"></i>Create Bikee Admin Users
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admins">
                    <i className="ri-edit-2-fill"></i>List of Bikee Admin Users
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/users">
                    <i className="ri-edit-2-fill"></i>List of Client's Admin
                    Users
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/reports" className="iq-waves-effect">
                <i className="ri-message-fill"></i>
                <span>Fault Reports</span>
              </Link>
            </li>
            <li>
              <Link to="/geo-reports" className="iq-waves-effect">
                <i className="ri-briefcase-4-fill"></i>
                <span>Fleet Geolocation Report</span>
              </Link>
            </li>
            <li>
              <Link to="/" className="iq-waves-effect" onClick={logout}>
                <i className=""></i>
                <span>Log out</span>
              </Link>
            </li>
          </ul>
        </nav>
        <div className="p-3"></div>
      </div>
    </div>
  );
};
