import { useMutation, useQueryClient } from "react-query";
import { apiFunctions } from "./api";
import { ToastError, ToastSuccess } from "./helpers";
import {
  GET_ALL_CLIENTS,
  GET_ASSIGNED_BIKES,
  GET_BIKEE_ADMIN_LIST,
  GET_BIKE_PARTS,
  GET_BIKE_TYPES,
  GET_CLIENT,
  GET_UNASSIGNED_BIKES,
  GET_VEHICLES,
} from "./query_keys";
import { useMemo } from "react";

export const usePostLockBike = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.lock_bike, {
    onSuccess: (resp) => {
      queryClient.invalidateQueries(GET_VEHICLES);
      queryClient.invalidateQueries(GET_UNASSIGNED_BIKES);
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onSuccess) return onSuccess(resp);
      ToastSuccess("Bike has been locked");
    },
    onError: (err) => {
      queryClient.invalidateQueries(GET_VEHICLES);
      queryClient.invalidateQueries(GET_UNASSIGNED_BIKES);
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onError) return onError(err);
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const usePostUnLockBike = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.unlock_bike, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_VEHICLES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Bike has been unlocked");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const usePostUnLockBattery = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.unlock_battery, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_VEHICLES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Battery has been unlocked");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const usePostLockBattery = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.lock_battery, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_VEHICLES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Battery has been locked");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const usePostRegister = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.register, {
    onSuccess: () => {
      ToastSuccess("Record has been saved");
      if (onSuccess) return onSuccess();
      queryClient.invalidateQueries(GET_ALL_CLIENTS);
      queryClient.invalidateQueries(GET_BIKEE_ADMIN_LIST);
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const usePutUpdateClient = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.update_client_info, {
    onSuccess: () => {
      ToastSuccess("Record has been updated");
      if (onSuccess) onSuccess();
      queryClient.invalidateQueries(GET_CLIENT);
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const usePostCreateBikeType = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.create_bike_type, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_BIKE_TYPES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Record has been saved");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const useEditBikeType = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.edit_bike_type, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_BIKE_TYPES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Record has been saved");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return {
    isLoading: mutation.isLoading,
    mutate: mutation.mutate,
  };
};

export const useCreateVehicles = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.create_vehicles, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_VEHICLES);
      queryClient.invalidateQueries(GET_UNASSIGNED_BIKES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Record has been saved");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const usePostAssignVehicles = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.assign_bikes, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_VEHICLES);
      queryClient.invalidateQueries(GET_UNASSIGNED_BIKES);
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Record has been saved");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const useCreateBikeParts = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.create_bike_parts, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_BIKE_PARTS);
      if (onSuccess) return onSuccess();
      ToastSuccess("Record has been saved");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const useSetBikeAlarm = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.set_alarm, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Bike alarm has been set");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const useRemoveBikeAlarm = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.remove_alarm, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Bike alarm has been unset");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const useRemoveAssignedBikes = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(apiFunctions.remove_assigned_bike, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_VEHICLES);
      queryClient.invalidateQueries(GET_UNASSIGNED_BIKES);
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Your changes have been saved");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};
