import React, { useEffect, useState } from "react";
import { MapContainer } from "../../../components/Map";

import { handleBikeApi, handlePostBikeApi } from "../../../functions/bike-api";

import {
  Capitalize,
  ToastError,
  ToastSuccess,
} from "../../../functions/helpers";
import { useFetchMetrics, useFetchVehicles } from "../../../functions/queries";
import PageLoader from "../../../components/PageLoader";
import numeral from "numeral";
import styles from "./styles";
import { CustomSwitch } from "../../../components/components";
import {
  usePostLockBattery,
  usePostLockBike,
  usePostUnLockBattery,
  usePostUnLockBike,
  useRemoveBikeAlarm,
  useSetBikeAlarm,
} from "../../../functions/mutations";
import { RollLoader } from "../../../components/lottie/loader/Loading";
import moment from "moment";

const Dashboard = () => {
  const [selectBike, setSelectedBike] = useState(null);

  const { data: metrics, isLoading } = useFetchMetrics();

  const { data: bikes, isLoading: isLoadingVehicles } = useFetchVehicles();

  const { mutate: setAlarm, isLoading: isSetting } = useSetBikeAlarm();

  const { mutate: removeAlarm, isLoading: isRemoving } = useRemoveBikeAlarm();

  const handleRemoveBikeAlarm = () => {
    removeAlarm(selectBike?.serial_number);
  };

  const handleSetBikeAlarm = () => {
    setAlarm(selectBike?.serial_number);
  };

  const handleSelectedBike = (bike) => {
    setSelectedBike(bike);
  };

  const onCompleteUsePostLockBike = () => {
    setSelectedBike({
      ...selectBike,
      lock_status:
        selectBike?.lock_status === "unlocked" ? "locked" : "unlocked",
    });
  };

  const onCompleteUsePostUnlockBattery = () => {
    setSelectedBike({
      ...selectBike,
      battery_lock_status:
        selectBike?.battery_lock_status === "unlocked" ? "locked" : "unlocked",
    });
  };

  const { mutate, isLoading: isLockingBike } = usePostLockBike(
    onCompleteUsePostLockBike
  );

  const { mutate: unlock, isLoading: isUnLockingBike } = usePostUnLockBike(
    onCompleteUsePostLockBike
  );

  const { mutate: lockBattery, isLoading: isLockingBattery } =
    usePostLockBattery(onCompleteUsePostUnlockBattery);

  const { mutate: unlockBattery, isLoading: isUnLockingBattery } =
    usePostUnLockBattery(onCompleteUsePostUnlockBattery);

  const handleLockBike = () => {
    mutate(selectBike?.serial_number);
  };
  const handleUnLockBike = () => {
    unlock(selectBike?.serial_number);
  };

  const handleLockBattery = () => {
    lockBattery(selectBike?.serial_number);
  };

  const handleUnLockBattery = () => {
    unlockBattery(selectBike?.serial_number);
  };

  return (
    <div className="container-fluid">
      {isLoading || isLoadingVehicles ? (
        <PageLoader />
      ) : (
        <React.Fragment>
          <div className="col-lg-12">
            <div className="row">
              {[
                {
                  title: "Active number of clients",
                  value: metrics?.active_client,
                },
                {
                  title: "Total riders",
                  value: metrics?.all_riders,
                },
                {
                  title: "Total area of operations",
                  value: metrics?.all_areas,
                },
                {
                  title: "Total co2 avoided (kg)",
                  value: metrics?.total_co2,
                },
                {
                  title: "Total distance covered (km)",
                  value: metrics?.total_distance,
                },
              ].map((el, i) => (
                <div className="col-md-6 col-lg-3" key={i}>
                  <div className="iq-card iq-card-block iq-card-stretch">
                    <div className="iq-card-body iq-bg-primary rounded">
                      <div>
                        <div>
                          <h2 className="mb-0">
                            <span className="counter">
                              {el?.value ? numeral(el?.value).format("0,0") : 0}
                            </span>
                          </h2>
                          <h5 className="">{el.title}</h5>
                        </div>
                        <div className="d-flex align-items-center justify-content-end mt-2">
                          <img
                            src="/assets/images/user/studentbike.png"
                            alt="profile-img"
                            style={styles.bike_icon}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div className="iq-card-body pb-0">
                <div className="row ">
                  <div className="col-lg-12">
                    <div className="iq-card">
                      <div className="iq-card-body p-0">
                        <p>Bike Distribution Map</p>
                        <div className="mapouter">
                          <div className="gmap_canvas">
                            <MapContainer
                              bikes={bikes}
                              setBike={handleSelectedBike}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selectBike ? (
            <div className="col-lg-12">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-card">
                        <div className="iq-card-body">
                          <form>
                            <div className=" row align-items-center">
                              <div className="form-group col-sm-3">
                                <label>
                                  Serial No: {selectBike?.serial_number || ""}
                                </label>
                              </div>
                              <div className="form-group col-sm-3">
                                <label>
                                  Battery: {selectBike?.battery_charge || ""}
                                </label>
                              </div>
                              <div className="form-group col-sm-3">
                                <label>
                                  Software Version:
                                  {selectBike?.code_number || ""}
                                </label>
                              </div>
                              <div className="form-group col-sm-3">
                                <label>
                                  Status:{" "}
                                  {selectBike?.lock_status
                                    ? Capitalize(selectBike?.lock_status)
                                    : ""}
                                </label>
                              </div>
                              <div className="form-group col-sm-3">
                                <label>
                                  Scanned Last:{" "}
                                  {selectBike?.last_active
                                    ? moment(selectBike?.last_active).format(
                                        "DD/MM/yyyy hh:mm:ss a"
                                      )
                                    : ""}
                                </label>
                              </div>
                              <div className="form-group col-sm-3">
                                <label>
                                  Current geo-location:{" "}
                                  {selectBike?.street_address}
                                </label>
                              </div>
                              <div className="form-group col-sm-3">
                                <div>
                                  <p className="mb-2"> Lock/Unlock Bike </p>
                                  {isLockingBike || isUnLockingBike ? (
                                    <RollLoader />
                                  ) : (
                                    <CustomSwitch
                                      value={
                                        selectBike.lock_status === "locked"
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        selectBike &&
                                        selectBike.lock_status === "locked"
                                          ? handleUnLockBike
                                          : handleLockBike
                                      }
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="form-group col-sm-3">
                                <div>
                                  <p className="mb-2"> Lock/Unlock Battery </p>
                                  {isLockingBattery || isUnLockingBattery ? (
                                    <RollLoader />
                                  ) : (
                                    <CustomSwitch
                                      value={
                                        selectBike &&
                                        selectBike.battery_lock_status ===
                                          "locked"
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        selectBike &&
                                        selectBike.battery_lock_status ===
                                          "locked"
                                          ? handleUnLockBattery
                                          : handleLockBattery
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="form-group col-sm-3">
                                <label> Set / Unset Alarm</label>
                                <div>
                                  {isSetting || isRemoving ? (
                                    <RollLoader />
                                  ) : (
                                    <CustomSwitch
                                      value={
                                        selectBike?.alarm_status === "on"
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        selectBike &&
                                        selectBike?.alarm_status === "on"
                                          ? handleRemoveBikeAlarm
                                          : handleSetBikeAlarm
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

export default Dashboard;
