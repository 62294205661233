import React, { useEffect, useState } from "react";
import { postAPIs, postApi } from "../../../../functions/api";
import {
  ToastError,
  ToastSuccess,
  validateEmail,
  validateFormEntries,
} from "../../../../functions/helpers";
import { useHistory } from "react-router-dom";
import {
  Loading,
  RollLoader,
} from "../../../../components/lottie/loader/Loading";
import { getAPIs, putAPIs, putApi } from "../../../../functions/api";
import { useParams } from "react-router-dom";
import InputField from "../../../../components/InputField";
import SelectField from "../../../../components/SelectField";
import {
  useFetchClient,
  useFetchCountries,
  useFetchCountryStates,
  useFetchIndustries,
  useFetchLcdaAreas,
  useFetchStateLCDAs,
} from "../../../../functions/queries";
import Button from "../../../../components/Button";
import {
  usePostRegister,
  usePutUpdateClient,
} from "../../../../functions/mutations";
import PageLoader from "../../../../components/PageLoader";

const AddClient = () => {
  const history = useHistory();
  const [admin, setAdmin] = React.useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    address: "",
    client_type: "ENTERPRISE",
    type: "client",
    status: "",
    area_id: "",
    lcda_id: "",
    name: "",
    country_id: "",
    state_id: "",
    industry_id: "",
  });

  const { id } = useParams();

  const { data: industries } = useFetchIndustries();

  const { data: countries } = useFetchCountries();

  const { data: states } = useFetchCountryStates(admin?.country_id);

  const { data: lcdas } = useFetchStateLCDAs(admin?.state_id);

  const { data: areas } = useFetchLcdaAreas(admin?.lcda_id);

  const onCompleteUsePostRegister = () => {
    history.push("/clients");
  };

  const { data: client, isLoading: loading } = useFetchClient(id);

  const { mutate, isLoading } = usePostRegister(onCompleteUsePostRegister);

  const { mutate: update, isLoading: isUpdating } = usePutUpdateClient(
    onCompleteUsePostRegister
  );

  useEffect(() => {
    if (client)
      setAdmin({
        ...admin,
        id: client?.data?.id,
        email: client?.data?.email,
        first_name: client?.data?.owner?.first_name,
        last_name: client?.data?.owner?.last_name,
        phone: client?.data?.phone,
        address: client?.data?.address,
        status: client?.data?.address,
        area_id: client?.data?.area?.id,
        lcda_id: client?.data?.lcda?.id,
        name: client?.data?.name,
        country_id: client?.data?.country_id,
        state_id: client?.data?.state_id,
        industry_id: client?.data?.industry_id,
        status: client?.data?.status,
      });
  }, [client]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let resp = validateFormEntries(admin, [
      "name",
      "first_name",
      "last_name",
      "country_id",
      "state_id",
      "lcda_id",
      "industry_id",
      "area_id",
    ]);
    if (!resp?.status) {
      return ToastError(resp?.msg);
    }
    if (!validateEmail(admin?.email))
      return ToastError("Please enter a valid email address");
    if (admin?.id) return update(admin);
    mutate(admin);
  };

  const onChangeHandler = (e) => {
    setAdmin({
      ...admin,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="container-fluid">
      {loading ? <PageLoader /> : null}
      <div className="row">
        <div className="col-lg-12">
          <div className="iq-edit-list-data">
            <div className="tab-content">
              <div
                className="tab-pane fade active show"
                id="personal-information"
                role="tabpanel"
              >
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">
                        Client's Business Information
                      </h4>
                    </div>
                  </div>
                  <div className="iq-card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row align-items-center">
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="Name">Business Name</label>
                          <InputField
                            value={admin?.name}
                            name="name"
                            onChange={onChangeHandler}
                            placeholder="Enter business name"
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="fname">First Name</label>
                          <InputField
                            value={admin?.first_name}
                            name="first_name"
                            onChange={onChangeHandler}
                            placeholder="Enter first name"
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="lastname">Last Name</label>
                          <InputField
                            value={admin?.last_name}
                            name="last_name"
                            onChange={onChangeHandler}
                            placeholder="Enter last name"
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="email">Email Address</label>
                          <InputField
                            value={admin?.email}
                            name="email"
                            type={"email"}
                            onChange={onChangeHandler}
                            placeholder="Enter email address"
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="phone">Phone Number</label>
                          <InputField
                            value={admin?.phone}
                            name="phone"
                            type={"number"}
                            onChange={onChangeHandler}
                            placeholder="Enter phone number"
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="industry">Industry </label>
                          <SelectField
                            name={"industry_id"}
                            value={admin?.industry_id}
                            onChange={onChangeHandler}
                            options={industries}
                            placeholder={"Select industry"}
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="Address">Address</label>
                          <InputField
                            value={admin?.address}
                            name="address"
                            onChange={onChangeHandler}
                            placeholder="Enter address"
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="country">Country</label>
                          <SelectField
                            name={"country_id"}
                            value={admin?.country_id}
                            onChange={onChangeHandler}
                            options={countries}
                            placeholder={"Select country"}
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="state">State </label>
                          <SelectField
                            name={"state_id"}
                            value={admin?.state_id}
                            onChange={onChangeHandler}
                            options={states}
                            placeholder={"Select state"}
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="lcda">Lcda</label>
                          <SelectField
                            name={"lcda_id"}
                            value={admin?.lcda_id}
                            onChange={onChangeHandler}
                            options={lcdas}
                            placeholder={"Select lcda"}
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="area">Area </label>
                          <SelectField
                            name={"area_id"}
                            value={admin?.area_id}
                            onChange={onChangeHandler}
                            options={areas}
                            placeholder={"Select area"}
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <label htmlFor="status">Status</label>
                          <SelectField
                            name={"status"}
                            value={admin?.status}
                            onChange={onChangeHandler}
                            options={["ACTIVE", "INACTIVE"]}
                            placeholder={"Select status"}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        {isLoading || isUpdating ? (
                          <RollLoader />
                        ) : (
                          <Button text={"Save"} type="submit" />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClient;
