import React from "react";
import { RollLoader } from "../../../../components/lottie/loader/Loading";
import {
  useFetchBikeReports,
  useFetchVehicles,
} from "../../../../functions/queries";
import PageLoader from "../../../../components/PageLoader";
import SelectField from "../../../../components/SelectField";
import ResponsiveTable from "../../../../components/ResponsiveTable";
import InputField from "../../../../components/InputField";

export default function GeoReport() {
  const [bike_id, setBikeId] = React.useState("");
  const [start_date, setStartDate] = React.useState("");
  const [end_date, setEndDate] = React.useState("");

  const columns = [
    {
      title: "Bike Number",
      field: "vehicle_serial",
    },
    {
      title: "Bike Volt",
      field: "battery_volt",
    },
    {
      title: "Address",
      field: "street_address",
    },
    {
      title: "Date & Time",
      field: "timestamp_created",
      is_date_time: true,
    },
  ];

  const { data: bikes, isLoading } = useFetchVehicles(1);

  const {
    data: reports,
    isLoading: loading,
    hasNextPage,
    hasPreviousPage,
    next_page_num,
    prev_page_num,
    page,
    total,
    limit,
  } = useFetchBikeReports(
    bike_id,
    new Date(start_date).getTime(),
    new Date(end_date).getTime()
  );

  const onChangeHandler = (e) => {
    if (e.target.name === "start_date") return setStartDate(e.target.value);
    if (e.target.name === "end_date") return setEndDate(e.target.value);
    setBikeId(e.target.value);
  };

  return (
    <div className="container-fluid">
      {isLoading || loading ? <PageLoader /> : null}
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">
                  This is a report of where all our fleets of ebikes have been
                  operating till date:
                </h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div id="table" className="table-editable">
                <div
                  className="tab-pane fade active show"
                  id="personal-information"
                  role="tabpanel"
                >
                  <div className="iq-card">
                    <div className="iq-card-body">
                      <div className="row align-items-center">
                        <div className="form-group col-sm-4">
                          <label>Select Bike:</label>
                          <SelectField
                            name={"bike"}
                            onChange={onChangeHandler}
                            options={bikes && Array.isArray(bikes) ? bikes : []}
                            value={bike_id}
                            placeholder={"Select bike"}
                          />
                        </div>
                        <div className="form-group col-sm-4">
                          <label>Start Date:</label>
                          <InputField
                            type="date"
                            onChange={onChangeHandler}
                            name={"start_date"}
                            value={start_date}
                          />
                        </div>
                        <div className="form-group col-sm-4">
                          <label>End Date:</label>
                          <InputField
                            type="date"
                            onChange={onChangeHandler}
                            name={"end_date"}
                            value={end_date}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ResponsiveTable
                  rows={reports}
                  columns={columns}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  prev_page_num={prev_page_num}
                  next_page_num={next_page_num}
                  limit={limit}
                  page={page}
                  total={total}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
