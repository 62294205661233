export const GET_METRICS = "get_metrics";
export const GET_VEHICLES = "get_vehicles";
export const GET_LOGS = "get_logs";
export const GET_INDUSTRIES = "get_industries";
export const GET_COUNTRIES = "get_countries";
export const GET_COUNTRY_STATES = "get_country_states";
export const GET_STATE_LCDA = "get_state_lcda";
export const GET_LCDA_AREAS = "get_lcda_areas";
export const GET_CLIENT = "get_client";
export const GET_ALL_CLIENTS = "get_all_clients";
export const GET_BIKEE_ADMIN_LIST = "get_bikee_admin_list";
export const GET_CLIENT_ADMIN_LIST = "get_client_admin_list";
export const GET_BIKE_TYPES = "get_bike_types";
export const GET_ASSIGNED_BIKES = "get_assigned_bikes";
export const GET_UNASSIGNED_BIKES = "get_unassigned_bikes";
export const GET_BIKE_PARTS = "get_bike_parts";
export const GET_FAULT_REPORTS = "get_fault_reports";
export const GET_BIKE_LOG = "get_bike_log";
