import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { ToastError, ToastSuccess } from "../../../functions/helpers";
import axios from "axios";
import { getAPIs, postAPIs, postApi } from "../../../functions/api";
import { Loading } from "../../../components/lottie/loader/Loading";

const CreateArea = () => {
  const history = useHistory();
  const [area, setArea] = useState({
    state: "",
    lcda: "",
    name: "",
    coord1Lng: "",
    coord1Lat: "",
    coord2Lng: "",
    coord2Lat: "",
    coord3Lng: "",
    coord3Lat: "",
    coord4Lng: "",
    coord4Lat: "",
    address: "",
    lng: "",
    lat: "",
    bike_rate: "",
    contact_name: "",
    contact_phonenumber: "",
    minimum_unlock_amount: "",
    rider_pause_amount: "",
    contact_role: "",
    contact_email: "",
    type_of_solution: "",
    number_of_bikes: "",
    bike_type: "",
    geolocation_available: false,
    bike_drop_off_zones: [],
    bike_pick_up_zones: [],
  });
  const [station, setStation] = useState({
    pickup_name: "",
    dropoff_name: "",
    pickup_lat: "",
    dropoff_lat: "",
    pickup_lng: "",
    dropoff_lng: "",
  });
  let required = [
    "state",
    "lcda",
    "name",
    "coord1Lng",
    "coord1Lat",
    "coord2Lng",
    "coord2Lat",
    "coord3Lng",
    "coord3Lat",
    "coord4Lng",
    "coord4Lat",
    "minimum_unlock_amount",
    "rider_pause_amount",
    "lng",
    "lat",
    "bike_rate",
    "contact_name",
    "contact_phonenumber",
    "contact_role",
    "contact_email",
    "type_of_solution",
    "number_of_bikes",
    "bike_type",
  ];
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [cstates, setCStates] = useState([]);
  const [bike_types, setBikeTypes] = useState([]);
  const [lcdas, setLcdas] = useState(null);
  const [stations, setStations] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let failed = false;
      for (let req of required) {
        if (area[req].toString().trim() === "" || stations.length === 0) {
          failed = true;
        }
      }
      if (failed) {
        return ToastError("All Fields are required");
      }
      setLoading(true);
      let data = {
        ...area,
        bike_drop_off_zones: stations,
        bike_pick_up_zones: stations,
        address: area.name,
        state: area.state.split("__")[0],
        newName: area.name,
      };
      let edit_area = JSON.parse(localStorage.getItem("edit_area"));
      let url = edit_area ? "/edit_area" : "/add_area";

      let res = await postApi(url, data);
      let msg = edit_area
        ? "Location was updated successfully"
        : "Location was added successfully";
      ToastSuccess(msg);
      setLoading(false);
      setArea({
        state: "",
        lcda: "",
        name: "",
        coord1Lng: "",
        coord1Lat: "",
        coord2Lng: "",
        coord2Lat: "",
        coord3Lng: "",
        coord3Lat: "",
        coord4Lng: "",
        coord4Lat: "",
        address: "",
        lng: "",
        lat: "",
        minimum_unlock_amount: "",
        rider_pause_amount: "",
        bike_rate: "",
        contact_name: "",
        contact_phonenumber: "",
        contact_role: "",
        contact_email: "",
        type_of_solution: "",
        number_of_bikes: "",
        bike_type: "",
        geolocation_available: false,
        bike_drop_off_zones: [],
        bike_pick_up_zones: [],
      });
      if (edit_area) history.push("/station_list");
    } catch (err) {
      setLoading(false);
      ToastError(err.msg);
    }
  };
  const addStation = () => {
    //All stations are both for drop ups and pick ups;
    let required = ["pickup_name", "pickup_lng", "pickup_lat"];
    let req2 = ["dropoff_lat", "dropoff_name", "dropoff_lng"];
    let failed = false;
    for (let req of required) {
      if (station[req].trim() === "") failed = true;
    }
    let failed2 = false;
    for (let req of req2) {
      if (station[req].trim() === "") failed2 = true;
    }
    if (failed && failed2) return ToastError("All Fields are required");
    let new_stations = [];
    if (!failed && !failed2) {
      new_stations = [
        {
          name: station.pickup_name,
          lng: station.pickup_lat,
          lat: station.pickup_lng,
          address: station.pickup_name,
        },
        {
          name: station.dropoff_name,
          lng: station.dropoff_lat,
          lat: station.dropoff_lng,
          address: station.dropoff_name,
        },
      ];
    }
    if (!failed && failed2) {
      new_stations = [
        {
          name: station.pickup_name,
          lng: station.pickup_lat,
          lat: station.pickup_lng,
          address: station.pickup_name,
        },
      ];
    }
    if (failed && !failed2) {
      new_stations = [
        {
          name: station.dropoff_name,
          lng: station.dropoff_lat,
          lat: station.dropoff_lng,
          address: station.dropoff_name,
        },
      ];
    }
    setStations([...stations, ...new_stations]);
    setStation({
      pickup_name: "",
      dropoff_name: "",
      pickup_lat: "",
      dropoff_lat: "",
      pickup_lng: "",
      dropoff_lng: "",
    });
  };
  const getData = async () => {
    try {
      setShow(true);
      let res = await getAPIs("/get_all_bikes_types");
      let res1 = await getAPIs("/get_all_states");
      let res2 = await getAPIs("/get_all_solution_plan");
      res && res.response_message && setBikeTypes(res.response_message);
      res1.response_message && setCStates(res1.response_message);
      res2.response_message && setSolutions(res2.response_message);
      let edit_area = JSON.parse(localStorage.getItem("edit_area"));
      if (edit_area) {
        setArea({
          ...edit_area,
          coord1Lng: edit_area.geo_fence.coord_1.lng,
          coord1Lat: edit_area.geo_fence.coord_1.lat,
          coord2Lng: edit_area.geo_fence.coord_2.lng,
          coord2Lat: edit_area.geo_fence.coord_2.lat,
          coord3Lng: edit_area.geo_fence.coord_3.lng,
          coord3Lat: edit_area.geo_fence.coord_3.lat,
          coord4Lng: edit_area.geo_fence.coord_4.lng,
          coord4Lat: edit_area.geo_fence.coord_1.lat,
          oldName: edit_area.name,
        });
        setStations(edit_area.bike_pick_up_zones);
      }
      setShow(false);
    } catch (err) {
      setShow(false);
      ToastError(err.msg);
    }
  };
  useEffect(() => {
    getData();
    return () => {
      localStorage.removeItem("edit_area");
    };
  }, []);
  return (
    <div className="container-fluid">
      {show ? (
        <div id="loading">
          <div id="loading-center"></div>
        </div>
      ) : null}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-sm-6">
            <label className="mt-3">Select State</label>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={(e) => {
                setArea({
                  ...area,
                  state: e.target.value,
                });
                let index = e.target.value.split("__")[1];
                setLcdas(cstates[index]);
              }}
              value={area.state}
            >
              <option value="">Select State</option>
              {cstates.map((item, index) => (
                <option value={`${item.name}__${index}`} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
            <label className="mt-3">Select LCDA</label>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={(e) => {
                setArea({
                  ...area,
                  lcda: e.target.value,
                });
              }}
              value={area.lcda}
            >
              <option value="">Select LCDA</option>
              {lcdas &&
                lcdas.lcda.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
            </select>
            <label for="fname" className="mb-2 mt-3">
              Community/Campus/City Name: (suggestion from google required)
            </label>
            <input
              type="text"
              className="form-control"
              id="fname"
              placeholder="Enter Name"
              onChange={(e) => {
                setArea({
                  ...area,
                  name: e.target.value,
                });
                // setArea({
                //     ...area, address : e.target.value
                // })
              }}
              value={area.name}
            />
            <label for="fullname" className="mt-3">
              Contact Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="full_name"
              placeholder="Enter Full Name"
              onChange={(e) => {
                setArea({
                  ...area,
                  contact_name: e.target.value,
                });
              }}
              value={area.contact_name}
            />
            <label for="phone" className="mt-3">
              Contact Phone Number:
            </label>
            <input
              type="text"
              className="form-control"
              id="phone"
              placeholder="Enter Phone nunber"
              onChange={(e) => {
                setArea({
                  ...area,
                  contact_phonenumber: e.target.value,
                });
              }}
              value={area.contact_phonenumber}
            />
            <label for="role" className="mt-3">
              Contact Role in Area of Operation:
            </label>
            <input
              type="text"
              className="form-control"
              id="role"
              placeholder="Enter Role"
              onChange={(e) => {
                setArea({
                  ...area,
                  contact_role: e.target.value,
                });
              }}
              value={area.contact_role}
            />
            <label for="fname" className="mt-3">
              Email Address:
            </label>
            <input
              type="text"
              className="form-control"
              id="fname"
              placeholder="Enter E-mail Address"
              onChange={(e) => {
                setArea({
                  ...area,
                  contact_email: e.target.value,
                });
              }}
              value={area.contact_email}
            />
            <label for="area_lat" className="mb-2 mt-3">
              Community/Campus/City Latitude:
            </label>
            <input
              type="text"
              className="form-control"
              id="area_lat"
              placeholder="Enter Latitude"
              onChange={(e) => {
                setArea({
                  ...area,
                  lat: e.target.value,
                });
              }}
              value={area.lat}
            />
            <label for="area_long" className="mb-2 mt-3">
              Community/Campus/City Longitude:
            </label>
            <input
              type="text"
              className="form-control"
              id="area_long"
              placeholder="Enter Longitude"
              onChange={(e) => {
                setArea({
                  ...area,
                  lng: e.target.value,
                });
              }}
              value={area.lng}
            />

            <div className="row">
              <div className="form-group col-sm-6 mt-4">
                <label for="pickup" className="mb-2 mt-3">
                  Create Pickup/ Drop Up Station Stations: (suggestion from
                  google required)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pickup_name"
                  placeholder="Enter Name"
                  onChange={(e) => {
                    setStation({
                      ...station,
                      pickup_name: e.target.value,
                    });
                  }}
                  value={station.pickup_name}
                />
                <label for="pickup_lat" className="mt-3">
                  Enter Longitude:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pickup_lat"
                  placeholder="Enter Longitude (e.g 347.8338)"
                  onChange={(e) => {
                    setStation({
                      ...station,
                      pickup_lat: e.target.value,
                    });
                  }}
                  value={station.pickup_lat}
                />
                <label for="pickup_long" className="mt-3">
                  Enter Latitude:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pickup_long"
                  placeholder="Enter Latitude (e.g 347.8338)"
                  onChange={(e) => {
                    setStation({
                      ...station,
                      pickup_lng: e.target.value,
                    });
                  }}
                  value={station.pickup_lng}
                />
              </div>
              <div className="form-group col-sm-6 mt-4">
                <label for="pickup" className="mb-2 mt-3">
                  Create Pickup/ Drop Up Station Stations: (suggestion from
                  google required)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fname"
                  placeholder="Enter Name"
                  onChange={(e) => {
                    setStation({
                      ...station,
                      dropoff_name: e.target.value,
                    });
                  }}
                  value={station.dropoff_name}
                />
                <label for="dropoff_name" className="mt-3">
                  Enter Longitude:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="dropoff_name"
                  placeholder="Enter Longitude (e.g 347.8338)"
                  onChange={(e) => {
                    setStation({
                      ...station,
                      dropoff_lat: e.target.value,
                    });
                  }}
                  value={station.dropoff_lat}
                />
                <label for="dropoff_long" className="mt-3">
                  Enter Latitude:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="dropoff_long"
                  placeholder="Enter Latitude (e.g 347.8338)"
                  onChange={(e) => {
                    setStation({
                      ...station,
                      dropoff_lng: e.target.value,
                    });
                  }}
                  value={station.dropoff_lng}
                />
                <button
                  type="button"
                  className="btn btn-primary mt-3 pull-right"
                  onClick={addStation}
                >
                  Add Station
                </button>
              </div>
            </div>
            <div>
              {stations.map((item, index) => (
                <p key={index}>
                  <span className="mr-2">{item.name}</span>
                  <span
                    className="fa fa-close text-danger cancel-btn"
                    onClick={() => {
                      let arr = [...stations];
                      arr.splice(index, 1);
                      setStations(arr);
                    }}
                  ></span>
                </p>
              ))}
            </div>
          </div>

          <div className="form-group col-sm-6">
            <label className="mt-3">Select Solution Plan</label>
            <select
              className="form-control"
              value={area.type_of_solution}
              onChange={(e) => {
                setArea({ ...area, type_of_solution: e.target.value });
              }}
            >
              <option value="">Select Solution</option>
              {solutions &&
                Array.isArray(solutions) &&
                solutions.map((item) => (
                  <option value={item.name}>{item.name}</option>
                ))}
            </select>
            <label className="mt-3">Assign Number of e-Bikes</label>
            <input
              type="number"
              className="form-control"
              value={area.number_of_bikes}
              onChange={(e) => {
                setArea({ ...area, number_of_bikes: e.target.value });
              }}
            />
            <label className="mt-3">Bike Rate (e.g. per minute)</label>
            <input
              type="number"
              className="form-control"
              value={area.bike_rate}
              onChange={(e) => {
                setArea({ ...area, bike_rate: e.target.value });
              }}
            />
            <label className="mt-3">
              Minimum Unlock amount (e.g. per minute)
            </label>
            <input
              type="number"
              className="form-control"
              value={area.minimum_unlock_amount}
              onChange={(e) => {
                setArea({ ...area, minimum_unlock_amount: e.target.value });
              }}
            />

            <label className="mt-3">Rider Pause amount (e.g. per minute)</label>
            <input
              type="number"
              className="form-control"
              value={area.rider_pause_amount}
              onChange={(e) => {
                setArea({ ...area, rider_pause_amount: e.target.value });
              }}
            />

            <label className="mt-3">Type of e-Bikes</label>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={(e) => {
                setArea({ ...area, bike_type: e.target.value });
              }}
              value={area.bike_type}
            >
              <option value="">Select Type</option>
              {bike_types &&
                bike_types.map((item, index) => (
                  <option
                    value={`${item.bike_type}`}
                    key={index}
                  >{`${item.bike_type}`}</option>
                ))}
            </select>
            <label className="mt-3">Service Require Geolocation?</label>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={(e) =>
                setArea({ ...area, geolocation_available: e.target.value })
              }
              value={area.geolocation_available}
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
            <div className="row">
              <div className="form-group col-sm-6 mt-4">
                <label className="mt-3">Create Geo-fencing Area</label>
                <label for="coord1Lat">Community/Campus/City Latitude:</label>
                <input
                  type="text"
                  className="form-control"
                  id="coord1Lat"
                  value={area.coord1Lat}
                  placeholder="Enter Latitude"
                  onChange={(e) =>
                    setArea({ ...area, coord1Lat: e.target.value })
                  }
                  //value={area.coord1Lat}
                />
                <label for="geo-lng-a" className="mt-3">
                  Community/Campus/City Longitude:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="coord1Lng"
                  placeholder="Enter Longitude"
                  onChange={(e) =>
                    setArea({ ...area, coord1Lng: e.target.value })
                  }
                  value={area.coord1Lng}
                />
                <label for="coord1Lat" className="mt-3">
                  Community/Campus/City Latitude:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="coord2Lat"
                  value={area.coord2Lat}
                  placeholder="Enter Latitude"
                  onChange={(e) =>
                    setArea({ ...area, coord2Lat: e.target.value })
                  }
                />
                <label for="geo-lng-a" className="mt-3">
                  Community/Campus/City Longitude:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="coord2Lng"
                  placeholder="Enter Longitude"
                  onChange={(e) =>
                    setArea({ ...area, coord2Lng: e.target.value })
                  }
                  value={area.coord2Lng}
                />
              </div>
              <div className="form-group col-sm-6 mt-4">
                <label className="mt-3">Create Geo-fencing Area</label>
                <label for="coord3Lat">Community/Campus/City Latitude:</label>
                <input
                  type="text"
                  className="form-control"
                  id="coord3Lat"
                  value={area.coord3Lat}
                  placeholder="Enter Latitude"
                  onChange={(e) =>
                    setArea({ ...area, coord3Lat: e.target.value })
                  }
                />
                <label for="geo-lng-a" className="mt-3">
                  Community/Campus/City Longitude:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="coord3Lng"
                  placeholder="Enter Longitude"
                  onChange={(e) =>
                    setArea({ ...area, coord3Lng: e.target.value })
                  }
                  value={area.coord3Lng}
                />
                <label for="coord1Lat" className="mt-3">
                  Community/Campus/City Latitude:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="coord4Lat"
                  value={area.coord1Lat}
                  placeholder="Enter Latitude"
                  onChange={(e) =>
                    setArea({ ...area, coord4Lat: e.target.value })
                  }
                  //value={area.coord4Lat}
                />
                <label for="geo-lng-a" className="mt-3">
                  Community/Campus/City Longitude:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="coord4Lng"
                  placeholder="Enter Longitude"
                  onChange={(e) =>
                    setArea({ ...area, coord4Lng: e.target.value })
                  }
                  value={area.coord4Lng}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 mb-3">
            <label for="fname">
              REMEMBER TO ASSIGN e-BIKES (e-bikes menu option) TO THE AREA AFTER
              SAVING.
            </label>
            <div className="pull-right">
              {loading ? (
                <Loading />
              ) : (
                <button type="submit" className="btn btn-primary">
                  SAVE
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateArea;
