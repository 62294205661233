import React from "react";
import Button from "../../../components/Button";
import { useHistory } from "react-router-dom";
import styles from "./styles";

const NotFound = () => {
  const history = useHistory();
  const onClick = () => {
    history.push("/dashboard");
  };
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="mt-5">
        <h3>Page Not Found</h3>
        <div className="d-flex justify-content-center mt-3">
          <Button
            text={"Back to Home"}
            style={styles.button}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
