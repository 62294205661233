import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import { useFetchMetrics } from "../../functions/queries";
import PageLoader from "../PageLoader";

const DefaultLayout = ({ children }) => {
  const { isLoading } = useFetchMetrics();
  return (
    <React.Fragment>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="wrapper">
          <Sidebar />
          {/* <!-- Page Content  --> */}
          <div id="content-page" className="content-page">
            <Header />

            {children}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DefaultLayout;
