import React, { useEffect, useState } from "react"
import { useHistory } from "react-router";
import { Header } from "../../../components/Header"
import { Sidebar } from "../../../components/Sidebar"
import { ToastError, ToastSuccess } from "../../../functions/helpers";
import axios from "axios";
import { getAPIs, postAPIs } from "../../../functions/api";

const Coupons = () => {
   const [show,setShow] = useState(true);
   const [coupons,setCoupons] = useState(null)
   const getData = async () => {
      try{
         let res = await getAPIs('/get_all_coupons')
         res && res.response_message && setCoupons(res.response_message);
         setShow(false);
      }catch(err){
         setShow(false)
         ToastError(err.msg)
      }
   }
   useEffect(()=>{
      getData()
   },[])
    return(
        <div class="container-fluid">
           {show ?  <div id="loading">
                <div id="loading-center"></div>
            </div> : null}
			<div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">All Aavailable Coupons</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div id="table" class="table-editable">
				   <div class="tab-pane fade active show" id="personal-information" role="tabpanel">
                              <div class="iq-card">                                 
                                 <div class="iq-card-body">
                                    <form>
                                       <div class="row align-items-center">
									    <div class="form-group col-sm-4">
                                             <label>Select Name of e-bike:</label>
                                             <select class="form-control" id="exampleFormControlSelect1">
                                                <option selected="">Boost</option>
                                                <option>Moov</option>
                                             </select>
									    </div>
										<div class="form-group col-sm-4">
                                             <label>Select Plan Solution:</label>
                                             <select class="form-control" id="exampleFormControlSelect1">
                                                <option selected="">Daily Sharing</option>
                                                <option>Next Day Sharing</option>
												<option>Weekly Rent</option>
												<option>Monthly Rent</option>
                                             </select>
									    </div>
										<div class="form-group col-sm-4">
                                             <label>Select State:</label>
                                             <select class="form-control" id="exampleFormControlSelect1">
                                                <option selected="">Lagos</option>
                                                <option>Abuja</option>
                                             </select>
									    </div>
										<div class="form-group col-sm-4">
                                             <label>Select LCDA:</label>
                                             <select class="form-control" id="exampleFormControlSelect1">
                                                <option selected="">Ikeja</option>
                                                <option>Lekki/Epe</option>
                                             </select>
									    </div>
										<div class="form-group col-sm-4">
                                             <label>Select Area:</label>
                                             <select class="form-control" id="exampleFormControlSelect1">
                                                <option selected="">Magodo</option>
                                             </select>
									    </div>										
																			   										
									   </div>
									 <button type="submit" class="btn btn-primary mr-2">Submit</button>						  
                                    </form>
                                 </div>
                              </div>							  
                           </div>
                                {/* <div className="mb-3">
                                    <label>Uploas a CSV File</label>			                               
                                    <button type="submit" class="btn btn-primary mr-2 ml-2">Download CSV File</button>	
                                </div> */}
                              <table class="table table-bordered table-responsive table-striped text-center">
                                 <thead>
                                    <tr>
                                       <th>S/No</th>
                                       <th>Product Type</th>
                                       <th>Plan Type</th>
									   <th>State</th>
                                       <th>LCDA</th>
                                       <th>Area</th>
									   <th>Coupon Name</th>
									   <th>Coupon Discount</th>
									   <th>Coupon Numbers</th>
									   <th>Retrieval</th>
                                       <th>Start</th>
                                       <th>End</th>
                                       <th colspan="2">Action</th>
                                       							   
                                    </tr>
                                 </thead>
                                 <tbody>


                                 {
                                       coupons && Array.isArray(coupons) && coupons.map((item,i)=>(
                                          <tr key={i}>
                                             <td>{i+1}</td>									  
                                             <td>{item.bike_name}</td>
                                             <td>{item.solution_plan}</td>
                                             <td>{item.state}</td>
                                             <td>{item.lcda}</td>
                                             <td>{item.area}</td>
                                             <td>{item.coupon_name}</td>
                                             <td>{item.coupon_discount}%</td>
                                             <td>{item.number_of_coupon}</td>
                                             <td>{item.claimed || 0} Claimed</td>
                                             <td>
                                                {item.start_date}
                                             </td>
                                             <td>
                                                {item.end_date}
                                             </td>	
                                             <td>
                                                <div class="custom-control custom-switch custom-switch-text custom-control-inline">
                                                <div class="custom-switch-inner">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch-11" checked="" />
                                                    <label class="custom-control-label" for="customSwitch-11" data-on-label="Resume" data-off-label="Pause">
                                                    </label>
                                                </div>
                                                </div>
                                                </td>
                                                <td>
                                                <a type="submit" class="btn btn-danger mr-2">Delete</a>
                                                </td>					   							   
                                          </tr>
                                       ))
                                    }
                                 </tbody>
                              </table>
							  </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
    )
}

export default Coupons;