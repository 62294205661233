import React, { useEffect, useState } from "react";

import { useFetchVehicles } from "../../../../functions/queries";
import { MapContainer } from "../../../../components/Map";
import PageLoader from "../../../../components/PageLoader";

const HeatMap = () => {
  const [positions, setPositions] = useState([]);

  const { data: bikes, isLoading } = useFetchVehicles();

  const mapDataToState = () => {
    if (bikes && Array.isArray(bikes)) {
      setPositions(
        bikes.map((bk) => {
          return {
            lat: bk.current_latitude,
            lng: bk.current_longitude,
          };
        })
      );
    }
  };

  useEffect(() => {
    mapDataToState();
  }, [bikes]);

  return (
    <div className="container-fluid">
      {isLoading ? <PageLoader /> : null}
      <div className="row">
        <div className="col-lg-12">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">
                  Ebike Location Map (heat map will be displayed here)
                </h4>
              </div>
              <div className="iq-card-header-toolbar d-flex align-items-center">
                <div className="dropdown">
                  <span
                    className="dropdown-toggle text-primary"
                    id="dropdownMenuButton5"
                    data-toggle="dropdown"
                  >
                    <i className="ri-more-fill"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-12 iq-card-body">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <MapContainer
                    heatmap={{
                      positions: positions,
                      options: {
                        radius: 45,
                        opacity: 0.9,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeatMap;
