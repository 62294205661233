import React, { useEffect, useState } from "react"
import { useHistory } from "react-router";
import { Header } from "../../../components/Header"
import { Sidebar } from "../../../components/Sidebar"
import { ToastError } from "../../../functions/helpers";
import axios from "axios";
import { getAPIs } from "../../../functions/api";
import numeral from "numeral";

const SolutionList = () => {
    const [show,setShow] = useState(true);
    const [loading,setLoading] = useState(false);
    const [solutions,setSolution] = useState([
        {
            charge_amount: "30",
            charge_type: "Minute",
            name_of_solution: "Moovment",
            solution_category: "Private",
            solution_plan: "Daily Sharing Now"
        }
    ]);
    const [solution_types,setSolutionType] = useState([]);
    const [holders,setHolders] = useState([]);
    const getData = async () => {
        try{
            setShow(true)
            let res = await getAPIs('/get_all_ebike_solution');
            let res1 = await getAPIs('/get_all_solution_types');
            res && res.response_message && setSolution(res.response_message);
            res && res.response_message && setHolders(res.response_message);
            res1 && res1.response_message && setSolutionType(res1.response_message);
            setShow(false);
        }catch(err){
            setShow(false);
            ToastError(err.msg)
        }
    }
     useEffect(()=>{
        getData()
     },[])
    return(
        <div className="container-fluid">
            {show ?  <div id="loading">
                <div id="loading-center"></div>
            </div> : null}
			<div className="row">
                  <div className="col-sm-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List of e-bike solution.</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <div id="table" className="table-editable">
				            <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                              <div className="iq-card">                                 
                                 <div className="iq-card-body">
                                    <form>
                                       <div className="row align-items-center">
									    <div className="form-group col-sm-4">
                                             <label>Select Solution Category Type:</label>
                                             <select className="form-control" id="exampleFormControlSelect1"
                                                onChange={(e)=>{
                                                    if(e.target.value === "") return setSolution(holders)
                                                    let filter = holders.filter((item,index)=>{
                                                        return item.solution_category === e.target.value
                                                    })
                                                    setSolution(filter)
                                                }}
                                             >
                                                <option value="">Select</option>
                                                {
                                                    solution_types.map((item,index)=>(
                                                        <option index={index}>{item.name}</option>
                                                    ))
                                                }
                                             </select>
											 </div>						
                                        																						 
                                       </div>
                                    </form>
                                 </div>
                              </div>							  
                           </div>
						   		<label>List of Solution</label>			                               
                                <table className="table table-bordered table-responsive-md table-striped text-center">
                                    <thead>
                                        <tr>
                                        <th>S/No</th>
                                        <th>Type of Solution</th>
                                        <th>Name of Solution</th>
                                        <th>Charge Amount</th>
                                        <th>Charge Time</th>
                                        {/* <th colspan="2">Action</th>                                                             */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            solutions.map((item,index)=>(
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.solution_category}</td>
                                                    <td>{item.name_of_solution}</td>
                                                    <td>NGN{numeral(item.charge_amount).format('0,0.00')}</td>
                                                    <td>{item.charge_type}</td>
                                                    {/* <td><a type="submit" className="btn btn-primary mr-2 text-white">Edit</a></td>
                                                    <td><a type="submit" className="btn btn-primary mr-2 text-white">Delete</a></td>							   							    */}
                                                
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
							  </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
    )
}

export default SolutionList;