import Modal from "react-modal";
import styles from "./styles";
import ResponsiveTable from "../ResponsiveTable";
import { useEffect, useState } from "react";

const ReportModal = ({ report, isVisible, onHide }) => {
  const [parts, setParts] = useState([]);

  const onClickHandler = (el) => {
    window?.open(report?.image_url || "#");
  };

  const columns = [
    {
      field: "name",
      title: "Bike Part",
    },
    {
      title: "Image",
      is_button: true,
      btn_text: "View Image",
      onClick: onClickHandler,
    },
  ];
  useEffect(() => {
    setParts(
      report?.issue.map((item) => {
        return {
          name: item,
        };
      })
    );
  }, [report]);
  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onHide}
      ariaHideApp={false}
      style={{
        content: styles.content,
        overlay: styles.overlay,
      }}
    >
      <div className="col-lg-12">
        <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div className="iq-card-header d-flex align-items-center">
            <div className="iq-header-title">
              <h4 className="card-title">
                Bike part{parts.length > 1 ? "s" : ""} that require changes
              </h4>
            </div>
          </div>
          <div className="iq-card-body">
            <ResponsiveTable columns={columns} rows={parts} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportModal;
