import React from "react";
import { Footer } from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";

const SharingActiveUsers = () => {
    return(
        <div class="container-fluid">
           
			<div class="row">
			   
				  <div class="col-lg-8" >
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height" style={{width: "1200px"}}>
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Daily Active Users</h4>
                           </div>
						   <form>
                                       <div class="row align-items-center">
									    <div class="form-group col-sm-4">
                                             <label>Select State:</label>
                                             <select class="form-control" id="exampleFormControlSelect1">
                                                <option selected="">Lagos</option>
                                                <option>Abuja</option>
												<option>Oyo</option>
                                             </select>
											 </div> 
                                            <div class="form-group col-sm-4">
                                             <label>Select LCDA:</label>
                                             <select class="form-control" id="exampleFormControlSelect1">
                                                <option selected="">Ikeja</option>
                                                <option>Ajah</option>
												<option>Maryland</option>
                                             </select>
											 </div>
											 <div class="form-group col-sm-4">
                                             <label>Select Area:</label>
                                             <select class="form-control" id="exampleFormControlSelect1">
                                                <option selected="">Agege</option>
                                                <option>Ogba</option>
												<option>Magodo</option>
                                             </select>
											 </div>
											 <div class="form-group col-sm-4">
                                              <button type="submit" class="btn btn-primary mr-2">Submit</button>											 
											 </div>
                                       </div>
                                    </form>
                           <div class="iq-card-header-toolbar d-flex align-items-center">
                              <div class="dropdown">
                                 <span class="dropdown-toggle text-primary" id="dropdownMenuButton5" data-toggle="dropdown">
                                 <i class="ri-more-fill"></i>
                                 </span>
                                 <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton5">
                                    <a class="dropdown-item" href="#"><i class="ri-printer-fill mr-2"></i>Print</a>
                                    <a class="dropdown-item" href="#"><i class="ri-file-download-fill mr-2"></i>Download</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="iq-card-body" >
						<div class="iq-card calender-small">
                        <div class="col-md-3">
                           <input type="text" class="flatpicker d-none" />
                        </div>
                     </div>
                           <div class="table-responsive">
                              <table class="table mb-0 table-borderless">
                                 <thead>
                                    <tr>
                                       <th scope="col">S/N</th>
                                       <th scope="col">Date Registered</th>
									   <th scope="col">Full Name</th>
                                       <th scope="col">Phone Number</th>
                                       <th scope="col">Area Registered</th>
                                       <th scope="col">Status</th>
                                       <th scope="col">Wallet Balance</th>
									   <th scope="col">Last Active Time</th>									   
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td>1</td>
									   <td>20/02/2020</td>                                       
                                       <td> <a href="users profile page.html">Toloulope</a></td>
									   <td>+2349093440570</td>
                                       <td>Omlole Phase 1</td>
									   <td>Active</td>
                                       <td>NGN2300.00</td>
                                       <td>13:03:34, 20.06.2021</td>
                                    </tr>                                  
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
				  </div>
            </div>
    )
}

export default SharingActiveUsers;