import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { ToastError } from "../../../functions/helpers";
import axios from "axios";
import { getAPIs } from "../../../functions/api";
import numeral from "numeral";
import { Link } from "react-router-dom";
import { FilterBox } from "../../../components/components";

const Payments = () => {
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState({});
  let counter = 1;
  const getData = async () => {
    try {
      let res = await getAPIs("/get_all_payment_records");
      setPayments(res.response_message);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      ToastError(err.msg);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="container-fluid">
      {loading ? (
        <div id="loading">
          <div id="loading-center"></div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-lg-12">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Total Payments</h4>
              </div>
              <div className="col-md-4">
                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                  <div className="iq-card-body">
                    <div className="iq-info-box d-flex align-items-center p-3">
                      <div className="info-image mr-3">
                        <img
                          src="./assets/images/user/studentbike.png"
                          className="img-fluid"
                          alt="image-box"
                        />
                      </div>
                      <div className="info-text">
                        <h3>NGN2,300</h3>
                        <span>Total Payments</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <FilterBox /> */}
              <div className="iq-card-header-toolbar d-flex align-items-center">
                <div className="dropdown">
                  <span
                    className="dropdown-toggle text-primary"
                    id="dropdownMenuButton5"
                    data-toggle="dropdown"
                  >
                    <i className="ri-more-fill"></i>
                  </span>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton5"
                  >
                    <a className="dropdown-item" href="#">
                      <i className="ri-printer-fill mr-2"></i>Print
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="ri-file-download-fill mr-2"></i>Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="iq-card-body">
              <div className="table-responsive">
                <div className="table mb-0 table-borderless">
                  {payments &&
                    Object.keys(payments) &&
                    Object.keys(payments).reverse() &&
                    Object.keys(payments)
                      .reverse()
                      .map((key, index) => (
                        <div key={index} className="mb-3">
                          <div>
                            <h5 className="ml-2">{key}</h5>
                            <table style={{ width: "100%" }}>
                              <thead>
                                <tr>
                                  <th scope="col">S/N</th>
                                  <th scope="col">Payment Date</th>
                                  <th scope="col">User</th>
                                  <th scope="col">Reference</th>
                                  <th scope="col">Payment Area</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Payment Gateway</th>
                                </tr>
                              </thead>
                              <tbody>
                                {payments[key].map((payment, i) => (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{payment.payment_date}</td>
                                    <td>
                                      <Link
                                        to={`/user_profile/${payment.userID}`}
                                      >
                                        {payment.firstname} {payment.lastname}
                                      </Link>
                                    </td>
                                    <td>{payment.payment_ref}</td>
                                    <td>Omlole Phase 1</td>
                                    <td>
                                      NGN
                                      {numeral(payment.amount).format("0,0.00")}
                                    </td>
                                    <td>{payment.payment_method}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
