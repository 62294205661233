import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { ToastError, ToastSuccess } from "../../../functions/helpers";
import axios from "axios";
import { getAPIs, postAPIs } from "../../../functions/api";
import { FilterBox } from "../../../components/components";
import { RollLoader } from "../../../components/lottie/loader/Loading";
import moment from "moment";

const CreateCoupons = () => {
  const [show, setShow] = useState(true);
  const [coupons, setCoupons] = useState(null);
  const [bike_types, setBikeType] = useState(null);
  const [solutions, setSolution] = useState(null);
  const [data, setData] = useState({
    bikeName: "",
    solution_plan: "",
    state: "",
    lcda: "",
    area: "",
    couponName: "",
    couponDiscount: "",
    numberOfCoupon: "",
    startDate: "",
    endDate: "",
  });
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    let required = [
      "bikeName",
      "solution_plan",
      "state",
      "lcda",
      "area",
      "couponName",
      "couponDiscount",
      "numberOfCoupon",
      "startDate",
      "endDate",
    ];
    try {
      e.preventDefault();
      let failed;
      for (let req of required) {
        if (data[req] == "" || data[req].trim() === "") failed = true;
      }
      if (failed) {
        return ToastError("All fields are required");
      }
      if (
        moment(data.startDate).isBefore(moment(new Date())) ||
        moment(data.endDate).isBefore(moment(data.startDate)) ||
        moment(data.endDate).isBefore(moment(new Date()))
      ) {
        return ToastError("Please set a future date");
      }
      setLoading(true);
      let fd = new FormData();
      for (let req of required) {
        if (req === "startDate" || req === "endDate") {
          fd.append(req, moment(data[req]).format("DD/MM/YYYY"));
        } else {
          fd.append(
            req,
            ["state", "lcda", "area"].includes(req)
              ? data[req].split("__")[0]
              : data[req]
          );
        }
      }
      let url = "/create_coupon";
      await postAPIs(url, fd);
      let res = await getAPIs("/get_all_coupons");
      res && res.response_message && setCoupons(res.response_message);
      setData({
        bikeName: "",
        solution_plan: "",
        state: "",
        lcda: "",
        area: "",
        couponName: "",
        couponDiscount: "",
        numberOfCoupon: "",
        startDate: "",
        endDate: "",
      });
      setLoading(false);
      ToastSuccess("Coupon has been created");
    } catch (err) {
      setLoading(false);
      ToastError(err.msg);
    }
  };
  const getData = async () => {
    try {
      let res = await getAPIs("/get_all_coupons");
      let res1 = await getAPIs("/get_all_bike_types");
      let res2 = await getAPIs("/get_all_solution_types");
      res && res.response_message && setCoupons(res.response_message);
      res1 && res1.response_message && setBikeType(res1.response_message);
      res2 && res2.response_message && setSolution(res2.response_message);
      setShow(false);
    } catch (err) {
      setShow(false);
      ToastError(err.msg);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="container-fluid">
      {show ? (
        <div id="loading">
          <div id="loading-center"></div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Create new coupon</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div id="table" className="table-editable">
                <div
                  className="tab-pane fade active show"
                  id="personal-information"
                  role="tabpanel"
                >
                  <div className="iq-card">
                    <div className="iq-card-body">
                      <form onSubmit={handleSubmit}>
                        <FilterBox
                          setPayload={(value) => {
                            setData({ ...data, ...value });
                          }}
                          notification={true}
                        />
                        <div className="row align-items-center mt-0">
                          <div className="form-group col-sm-4">
                            <label>Select Name of e-bike:</label>
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              name="bikeName"
                              value={data.bikeName}
                              onChange={(e) =>
                                setData({ ...data, bikeName: e.target.value })
                              }
                            >
                              <option selected="">Select type</option>
                              {bike_types &&
                                bike_types.map((item) => (
                                  <option value={item.bike_type}>
                                    {item.bike_type}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="form-group col-sm-4">
                            <label>Select Plan Solution:</label>
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              value={data.solution_plan}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  solution_plan: e.target.value,
                                })
                              }
                            >
                              <option selected="">Select Plan</option>
                              {solutions &&
                                Array.isArray(solutions) &&
                                solutions.map((item) => (
                                  <option value={item.name}>{item.name}</option>
                                ))}
                            </select>
                          </div>
                          <div className="form-group col-sm-4">
                            <label for="fname">Promo Code Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="fname"
                              placeholder="Enter Promo Name"
                              value={data.couponName}
                              onChange={(e) =>
                                setData({ ...data, couponName: e.target.value })
                              }
                            />
                          </div>
                          <div className="form-group col-sm-4">
                            <label for="fname">Promo Code Discount</label>
                            <input
                              type="number"
                              className="form-control"
                              id="fname"
                              placeholder="Enter Promo Discount in %"
                              value={data.couponDiscount}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  couponDiscount: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="form-group col-sm-4">
                            <label>Code Numbers:</label>
                            <input
                              className="form-control"
                              value={data.numberOfCoupon}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  numberOfCoupon: e.target.value,
                                })
                              }
                            />
                            {/* <select className="form-control" 
                                                id="exampleFormControlSelect1"
                                                value={data.numberOfCoupon}
                                                onChange={(e)=>setData({...data,numberOfCoupon : e.target.value })}
                                             >
                                                <option value="">Select number</option>
                                                <option value="1">1</option>
												            <option value="2">2</option>
                                             </select> */}
                          </div>
                          <div className="form-group col-sm-4">
                            <label for="fname">Start Date</label>
                            <input
                              type="date"
                              className="form-control"
                              value={data.startDate}
                              onChange={(e) =>
                                setData({ ...data, startDate: e.target.value })
                              }
                            />
                          </div>
                          <div className="form-group col-sm-4">
                            <label for="fname">End Date</label>
                            <input
                              type="date"
                              className="form-control"
                              value={data.endDate}
                              onChange={(e) =>
                                setData({ ...data, endDate: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        {loading ? (
                          <div className="text-right">
                            <RollLoader />
                          </div>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary mr-2 pull-right"
                          >
                            Create
                          </button>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
                {/* <div className="mb-3">
                                   <label>Uploads a CSV File</label>			                               
							  	    <button type="submit" className="btn btn-primary mr-2 ml-2">Download CSV File</button>	
                                </div>					   */}
                <table className="table table-bordered table-responsive table-striped text-center">
                  <thead>
                    <tr>
                      <th>S/No</th>
                      <th>Product Type</th>
                      <th>Plan Type</th>
                      <th>State</th>
                      <th>LCDA</th>
                      <th>Area</th>
                      <th>Coupon Name</th>
                      <th>Coupon Discount</th>
                      <th>Coupon Numbers</th>
                      <th>Retrieval</th>
                      <th>Start</th>
                      <th>End</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coupons &&
                      Array.isArray(coupons) &&
                      coupons.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.bike_name}</td>
                          <td>{item.solution_plan}</td>
                          <td>{item.state}</td>
                          <td>{item.lcda}</td>
                          <td>{item.area}</td>
                          <td>{item.coupon_name}</td>
                          <td>{item.coupon_discount}%</td>
                          <td>{item.number_of_coupon}</td>
                          <td>{item.claimed || 0} Claimed</td>
                          <td>{item.start_date}</td>
                          <td>{item.end_date}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCoupons;
