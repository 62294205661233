import React from "react";
import {
  useFetchCountries,
  useFetchCountryStates,
  useFetchLcdaAreas,
  useFetchStateLCDAs,
} from "../../../../functions/queries";
import SelectField from "../../../../components/SelectField";

const AreaList = () => {
  const [filter, setFilter] = React.useState({
    country_id: "",
    state_id: "",
    lcda_id: "",
  });

  const { data: countries } = useFetchCountries();

  const { data: states } = useFetchCountryStates(filter?.country_id);

  const { data: lcdas } = useFetchStateLCDAs(filter?.state_id);

  const { data: areas } = useFetchLcdaAreas(filter?.lcda_id);

  const onChangeHandler = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">
                  List of created areas of operation.
                </h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div className="row">
                <div className="col-md-3">
                  <SelectField
                    name="country_id"
                    onChange={onChangeHandler}
                    options={
                      countries && Array.isArray(countries) ? countries : []
                    }
                    placeholder={"Select country"}
                    value={filter?.country_id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AreaList;
