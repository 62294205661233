import React from "react";
import { useHistory } from "react-router";
import ResponsiveTable from "../../../../components/ResponsiveTable";
import { useFetchAllClients } from "../../../../functions/queries";
import EmptyStateWrapper from "../../../../components/EmptyStateWrapper";
import PageLoader from "../../../../components/PageLoader";

const ClientList = () => {
  let navigate = useHistory();

  const {
    data: clients,
    isLoading,
    prev_page_num,
    hasNextPage,
    limit,
    hasPreviousPage,
    next_page_num,
    page,
    total,
  } = useFetchAllClients();

  const onClickHandler = (client) => {
    navigate.push(`/edit_client/${client?.id}`);
  };

  const columns = [
    {
      title: "Email Address",
      field: "email",
    },
    {
      title: "Business Name",
      field: "name",
    },
    {
      title: "Phone Number",
      field: "phone",
    },

    {
      title: "Status",
      field: "status",
    },

    {
      title: "Date Created",
      field: "created_at",
      is_date: true,
    },
    {
      is_button: true,
      onClick: onClickHandler,
      title: "Action",
      btn_text: "Edit",
    },
  ];

  return (
    <div>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-header d-flex justify-content-between mt-3">
                  <div className="iq-header-title">
                    <h4 className="card-title">List all Clients</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <ResponsiveTable
                    rows={clients}
                    columns={columns}
                    limit={limit}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    total={total}
                    page={page}
                    next_page_num={next_page_num}
                    prev_page_num={prev_page_num}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientList;
