import React from "react";

export const Header = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <div className="iq-top-navbar">
      <div className="iq-navbar-custom">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="iq-menu-bt align-self-center">
            <div className="wrapper-menu">
              <div className="main-circle">
                <i className="ri-more-fill"></i>
              </div>
              <div className="hover-circle">
                <i className="ri-more-2-fill"></i>
              </div>
            </div>
          </div>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
          ></div>
          <ul className="navbar-list">
            <li>
              <a
                href="#"
                className="search-toggle iq-waves-effect d-flex align-items-center"
              >
                <img
                  src="/assets/images/user/profile.png"
                  className="img-fluid rounded mr-3"
                  alt="user"
                />
                <div className="caption">
                  <h6 className="mb-0 line-height">
                    {(user && user.first_name) || "Admin"}
                  </h6>
                </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
