import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { ToastError, ToastSuccess } from "../../../functions/helpers";
import axios from "axios";
import { getAPIs, postAPIs } from "../../../functions/api";
import { Link } from "react-router-dom";
import { Loading } from "../../../components/lottie/loader/Loading";

const UnassignBikes = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [bikes, setBikes] = useState([]);
  const [bike_types, setBikeTypes] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selected, setSelected] = useState([]);
  const [area, setArea] = useState(null);
  const [holders, setHolders] = useState([]);

  const addOrRemoveItem = (e, imei) => {
    if (selected.includes(imei)) {
      let index = selected.indexOf(imei);
      let arr = [...selected];
      arr.splice(index, 1);
      return setSelected(arr);
    }
    let arr = [...selected, imei];
    setSelected(arr);
  };

  const getData = async () => {
    try {
      setShow(true);
      let res = await getAPIs("/get_all_unassigned_bikes");
      let res1 = await getAPIs("/get_all_bikes_types");
      let res2 = await getAPIs("/get_all_areas");
      res1.response_message && setBikeTypes(res1.response_message);
      res.response_message && setBikes(Object.values(res.response_message));
      res.response_message && setHolders(Object.values(res.response_message));
      res2.response_message && setAreas(res2.response_message);
      setShow(false);
    } catch (err) {
      setShow(false);
      ToastError(err.msg);
    }
  };

  const handleSubmit = async () => {
    try {
      if (selected.length === 0 || area === null || area === "") {
        return ToastError("Please choose area and bike to assign");
      }
      let data = {
        IMEI: selected,
        area: area,
      };
      setLoading(true);
      await postAPIs("/assign_bike_to_area", data);
      let res = await getAPIs("/get_all_unassigned_bikes");
      res.response_message && setBikes(res.response_message);
      setLoading(false);
      setSelected([]);
      setArea("");
      let msg =
        selected.length > 1
          ? "Selected bikes have been assigned"
          : "Selected bike has been assigned";
      ToastSuccess(msg);
    } catch (err) {
      setLoading(false);
      ToastError(err.msg);
    }
  };

  useEffect(() => {
    //getData();
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Unassigned e-bikes: 150 ebikes</h4>
                <span>50 Moov Lite, 100 Moov Express</span>
              </div>
            </div>
            <div className="iq-card-body">
              <div id="table" className="table-editable">
                <div
                  className="iq-search-bar"
                  style={{ marginBottom: "10px" }}
                ></div>

                <table className="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th>S/No</th>
                      <th>Product Type</th>
                      <th>IMEI Number</th>
                      <th>Bike Count Number</th>
                      <th>Stationed Bike Lat/Long</th>
                      <th>Location of ebike</th>
                      <th>Last Active</th>
                      <th>Status</th>
                      <th>Battery Level</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Boost</td>
                      <td>785277</td>
                      <td>B626782</td>
                      <td>
                        <a href="dashboard-3.html">2.897979/498989</a>
                      </td>
                      <td>Wareehouse</td>
                      <td>14:24:12, Wed June 2021</td>
                      <td>Locked</td>
                      <th>60%</th>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Boost</td>
                      <td>987564</td>
                      <td>B098768</td>
                      <td>
                        <a href="dashboard-3.html">2.897979/498989</a>
                      </td>
                      <td>Warehouse</td>
                      <td>14:24:12, Wed June 2021</td>
                      <td>Locked</td>
                      <th>60%</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnassignBikes;
