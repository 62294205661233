import React from "react"


const RentalPaymentType = () => (
    <div className="container-fluid">
           
    <div className="row">
          <div className="col-sm-12">
             <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                   <div className="iq-header-title">
                      <h4 className="card-title">Create Client's Solution Payment Type.</h4>
                   </div>
                </div>
                <div className="iq-card-body">
                   <div id="table" className="table-editable">
           <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                      <div className="iq-card">                                 
                         <div className="iq-card-body">
                            <form>
                               <div className="row align-items-center">
                                <div className="form-group col-sm-4">
                                     <label>Select Solution Category:</label>
                                     <select className="form-control" id="exampleFormControlSelect1">
                                        <option selected="">Rent-to-own</option>
                                        <option>Rental</option>
                                        <option>Outright Purchase</option>
                                     </select>
                                </div>
                                <div className="form-group col-sm-4">
                                     <label>Select Payment Plan:</label>
                                     <select className="form-control" id="exampleFormControlSelect1">
                                        <option selected="">Monthly Payment</option>
                                        <option>Quarterly Payment</option>
                                     </select>
                                </div>
                                <div className="form-group col-sm-4">	 
                                <label for="fname">Name of Solution for Client</label>
                               <input type="text" className="form-control" id="fname" placeholder="Enter Name" />
                               </div>
                               <div className="form-group col-sm-4">
                                <label for="fname">Charge Amount (monthly/quarterlys)</label>
                               <input type="text" className="form-control" id="fname" placeholder="Enter Amount" />
                               </div>
                               <div className="form-group col-sm-4">
                                     <label>Select Charge Time:</label>
                                     <select className="form-control" id="exampleFormControlSelect1">
                                        <option selected="">Monthly</option>
                                        <option>Quarterly</option>
                                     </select>
                                </div>
                                                                                                                                                                
                               </div>
                            </form>
                         </div>
                              <button type="submit" className="btn btn-primary mr-2">Save</button>
                      </div>							  
                   </div>
                           <label>Created Solution Type</label>			                               
                      <table className="table table-bordered table-responsive-md table-striped text-center">
                         <thead>
                            <tr>
                               <th>S/No</th>
                               <th>Solution Category</th>
                               <th>Name of Solution in Operation Area</th>
                               <th>Charge Amount</th>
                               <th>Charge Time</th>
                               <th>Edit Name</th>
                               <th>Delete</th>
                                                              
                            </tr>
                         </thead>
                         <tbody>
                            <tr>
                               <td>1</td>
                               <td>Rent-to-own</td>
                               <td>JISE TECHNOLOGY LIMITED (Rent-to-own)</td>
                               <td>NGN59,000.00</td>
                               <td>Monthly</td>
                               <td><a type="submit" className="btn btn-primary mr-2">Edit</a></td>
                               <td><a type="submit" className="btn btn-primary mr-2">Delete</a></td>							   							   
                              
                            </tr>
                            <tr>
                               <td>2</td>
                               <td>Rent-to-own</td>									   
                               <td>KFC LIMITED (Rent-to-own)</td>
                               <td>NGN150,000.00</td>
                               <td>Monthly</td>
                               <td><a type="submit" className="btn btn-primary mr-2">Edit</a></td>
                               <td><a type="submit" className="btn btn-primary mr-2">Delete</a></td>									   
                            </tr>
                            <tr>
                               <td>3</td>
                               <td>Rent-to-own</td>
                               <td>Sweet Sensation (Rent-to-own)</td>
                               <td>NGN400,000.00</td>
                               <td>Quarterly</td>
                               <td><a type="submit" className="btn btn-primary mr-2">Edit</a></td>
                               <td><a type="submit" className="btn btn-primary mr-2">Delete</a></td>									   
                            </tr>
                         </tbody>
                      </table>
                      </div>
                </div>
             </div>
          </div>
       </div>
    </div>
)

export default RentalPaymentType