import React from "react";
import moment from "moment";
import Modal from "react-modal";
import styles from "./styles";

const LogModal = ({ log, isVisible, onHide }) => {
  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onHide}
      ariaHideApp={false}
      style={{
        content: styles.content,
        overlay: styles.overlay,
      }}
    >
      <div className="col-lg-12">
        <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div className="iq-card-body">
            <div className="table-responsive mt-4">
              <table className="table table-bordered table-responsive-md table-striped text-center">
                <thead>
                  <tr>
                    <th>S/No</th>
                    <th>Timestamp</th>
                    <th>Latitude/Longitude</th>
                    <th> Battery Status</th>
                    <th>Bike Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h4>1</h4>
                    </td>
                    <td>
                      {moment(log?.vehicle?.timestamp_created).format(
                        "DD/MM/YYYY, h:mm a"
                      )}
                    </td>
                    <td>
                      <a href="area of operations.html">
                        {log?.vehicle?.current_longitude}/
                        {log?.vehicle?.current_latitude}
                      </a>
                    </td>
                    <td>{log?.vehicle?.battery_level}</td>
                    <td>{log?.vehicle?.lock_status}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LogModal;
