import React from "react";

const InputField = ({
  onChange,
  name,
  placeholder,
  type,
  style,
  disabled,
  value,
  required,
  checked = false,
}) => {
  return (
    <input
      type={type || "text"}
      className="form-control"
      id={name}
      name={name}
      disabled={disabled}
      required={required}
      placeholder={placeholder}
      value={value || ""}
      checked={checked}
      onChange={onChange}
      style={style}
    />
  );
};

export default InputField;
