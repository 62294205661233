import React from "react";
import moment from "moment";
import { useFectchRealTimeLogs } from "../../../../functions/queries";
import ResponsiveTable from "../../../../components/ResponsiveTable";
import PageLoader from "../../../../components/PageLoader";
import LogModal from "../../../../components/LogModal";

const Realtime = () => {
  const {
    data: logs,
    isLoading,
    next_page_num,
    prev_page_num,
    page,
    hasNextPage,
    hasPreviousPage,
    total,
    limit,
  } = useFectchRealTimeLogs();
  const [isVisible, setIsVisible] = React.useState(false);
  const [selected, setSelected] = React.useState({});

  const onClickHandler = (item) => {
    setSelected(item);
    setIsVisible(true);
  };

  const onHide = () => {
    setIsVisible(false);
  };

  const columns = [
    {
      is_date_time: true,
      title: "Date",
      field: "created_at",
    },
    {
      title: "battery level",
      field: "vehicle",
      subfield: "battery_level",
    },
    {
      title: "current location",
      field: "vehicle",
      subfield: "street_address",
    },
    {
      is_full_name: true,
      title: "Rider",
      field: "rider",
      subfield: "first_name",
    },
    {
      is_button: true,
      onClick: onClickHandler,
      title: "Bike Number",
      field: "vehicle",
      subfield: "serial_number",
    },
  ];

  return (
    <div className="container-fluid">
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title mt-4">
                    Ongoing delivery trips real time informtion
                  </h4>
                  <p>
                    Today's Date and Current Time:{" "}
                    <span id="datetime">
                      <b>
                        {moment(Date.now()).format("h:mm:ss a, DD/MM/YYYY")}
                      </b>
                    </span>
                  </p>
                </div>
              </div>
              <div className="iq-card-body">
                <ResponsiveTable
                  rows={logs}
                  columns={columns}
                  limit={limit}
                  total={total}
                  hasNextPage={hasNextPage}
                  page={page}
                  next_page_num={next_page_num}
                  prev_page_num={prev_page_num}
                  hasPreviousPage={hasPreviousPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <LogModal isVisible={isVisible} log={selected} onHide={onHide} />
    </div>
  );
};

export default Realtime;
