import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import {
  confirmAction,
  ToastError,
  ToastSuccess,
} from "../../../functions/helpers";
import axios from "axios";
import { getAPIs, postAPIs } from "../../../functions/api";
import numeral from "numeral";
import { Loading, RollLoader } from "../../../components/lottie/loader/Loading";

const CreateSolution = () => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [bike_types, setBikeTypes] = useState([]);
  const [solutions, setSolution] = useState([]);
  const [categories, setCategories] = useState([]);
  const [plans, setPlans] = useState([]);
  const [outside, setOutside] = useState(true);
  const [deleteItem, setDeleteItem] = useState("");
  const [processing, setProcess] = useState(false);
  const [data, setData] = useState({
    solutionCategory: "",
    nameOfSolution: "",
    chargeAmount: "",
    chargeType: "",
    solutionPlan: "",
  });

  const actFunction = async (deleteItem) => {
    try {
      setProcess(true);
      let fd = new FormData();
      fd.append("nameOfSolution", deleteItem);
      await postAPIs("/delete_ebike_solution", fd);
      let res = await getAPIs("/get_all_ebike_solution");
      res && res.response_message && setSolution(res.response_message);
      setProcess(false);
      setOutside(true);
      ToastSuccess("Item has been deleted");
    } catch (err) {
      setProcess(false);
      setOutside(true);
      ToastError(err.msg);
    }
  };
  const handleSubmit = async (e) => {
    let required = data.edit
      ? [
          "solutionCategory",
          "nameOfSolution",
          "newNameOfSolution",
          "oldNameOfSolution",
          "chargeAmount",
          "chargeType",
          "solutionPlan",
        ]
      : [
          "solutionCategory",
          "nameOfSolution",
          "chargeAmount",
          "chargeType",
          "solutionPlan",
        ];
    try {
      e.preventDefault();
      let failed;
      for (let req of required) {
        if (data[req] == "" || data[req].trim() === "") failed = true;
      }
      if (failed) {
        return ToastError("All fields are required");
      }
      setLoading(true);
      let fd = new FormData();
      for (let req of required) {
        fd.append(req, data[req]);
      }
      let url = data.edit ? "/edit_ebike_solution" : "/create_ebike_solution";
      await postAPIs(url, fd);
      let res = await getAPIs("/get_all_ebike_solution");
      res && res.response_message && setSolution(res.response_message);
      setData({
        solutionCategory: "",
        nameOfSolution: "",
        chargeAmount: "",
        chargeType: "",
        solutionPlan: "",
      });
      setLoading(false);
      ToastSuccess("Bike solution has been saved");
    } catch (err) {
      setLoading(false);
      ToastError(err.msg);
    }
  };
  const getData = async () => {
    try {
      let res = await getAPIs("/get_all_ebike_solution");
      let res1 = await getAPIs("/get_all_solution_types");
      let res2 = await getAPIs("/get_all_solution_plan");
      res && res.response_message && setSolution(res.response_message);
      res1 && res1.response_message && setCategories(res1.response_message);
      res2 && res2.response_message && setPlans(res2.response_message);
      setShow(false);
    } catch (err) {
      setShow(false);
      ToastError(err.msg);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="container-fluid">
      {show ? (
        <div id="loading">
          <div id="loading-center"></div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">
                  Create new area of operations e-bike solution.
                </h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div id="table" className="table-editable">
                <div
                  className="tab-pane fade active show"
                  id="personal-information"
                  role="tabpanel"
                >
                  <div className="iq-card">
                    <div className="iq-card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="row align-items-center">
                          <div className="form-group col-sm-4">
                            <label>Select Solution Category:</label>
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  solutionCategory: e.target.value,
                                })
                              }
                              value={data.solutionCategory}
                            >
                              <option value=" ">Select Category</option>
                              {categories.map((cat, index) => (
                                <option value={cat.name} key={index}>
                                  {cat.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group col-sm-4">
                            <label>Select Solution Plan:</label>
                            <select
                              className="form-control"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  solutionPlan: e.target.value,
                                })
                              }
                              value={data.solutionPlan}
                            >
                              <option value="">Select Plan</option>
                              {plans &&
                                Array.isArray(plans) &&
                                plans.map((item, i) => (
                                  <option value={item.name} key={i}>
                                    {item.name}
                                  </option>
                                ))}
                              {/* {
                                                    categories.map((cat,index)=>(
                                                        <option value={cat.name} key={index}>{cat.name}</option>
                                                    ))
                                                } */}
                            </select>
                          </div>
                          <div className="form-group col-sm-4">
                            <label for="fname">Name of Solution</label>
                            <input
                              type="text"
                              className="form-control"
                              id="fname"
                              placeholder="Enter Name"
                              onChange={(e) => {
                                if (data.edit) {
                                  return setData({
                                    ...data,
                                    nameOfSolution: e.target.value,
                                    newNameOfSolution: e.target.value,
                                  });
                                }
                                setData({
                                  ...data,
                                  nameOfSolution: e.target.value,
                                });
                              }}
                              value={data.nameOfSolution}
                            />
                          </div>
                          <div className="form-group col-sm-4">
                            <label for="fname">
                              Charge Amount (min/hrs/days)
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="fname"
                              placeholder="Enter Amount"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  chargeAmount: e.target.value,
                                })
                              }
                              value={data.chargeAmount}
                            />
                          </div>
                          <div className="form-group col-sm-4">
                            <label for="fname">Charge Time</label>
                            <select
                              className="form-control"
                              id="fname"
                              onChange={(e) =>
                                setData({ ...data, chargeType: e.target.value })
                              }
                              value={data.chargeType}
                            >
                              <option value="">Select</option>
                              <option value="Minutes">Minutes</option>
                              <option value="Hours">Hours</option>
                              <option value="Daily">Daily</option>
                              <option value="Monthly">Monthly</option>
                            </select>
                          </div>
                        </div>
                        {loading ? (
                          <div className="pull-right">
                            <Loading />
                          </div>
                        ) : (
                          <div className="text-right">
                            <button
                              type="submit"
                              className="btn btn-primary mr-2"
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
                <label>Created Solution Type</label>
                <table className="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th>S/No</th>
                      <th>Solution Category</th>
                      <th>Solution Plan</th>
                      <th>Name of Solution</th>
                      <th>Charge Amount</th>
                      <th>Charge Time</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {solutions.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.solution_category}</td>
                        <td>{item.solution_plan}</td>
                        <td>{item.name_of_solution}</td>
                        <td>
                          NGN{numeral(item.charge_amount).format("0,0.00")}
                        </td>
                        <td>{item.charge_type}</td>
                        <td>
                          <a
                            type="submit"
                            className="btn btn-primary mr-2 text-white"
                            onClick={(e) => {
                              e.preventDefault();
                              setData({
                                solutionCategory: item.solution_category || " ",
                                nameOfSolution: item.name_of_solution,
                                chargeAmount: item.charge_amount,
                                chargeType: item.charge_type,
                                solutionPlan: item.solution_plan,
                                oldNameOfSolution: item.name_of_solution,
                                newNameOfSolution: item.name_of_solution,
                                edit: true,
                              });
                            }}
                          >
                            Edit
                          </a>
                        </td>
                        <td>
                          {processing &&
                          item.name_of_solution === deleteItem ? (
                            <RollLoader />
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary mr-2 text-white"
                              onClick={() => {
                                setDeleteItem(item.name_of_solution);
                                confirmAction(
                                  outside,
                                  setOutside,
                                  actFunction,
                                  processing,
                                  item.name_of_solution,
                                  item.name_of_solution
                                );
                              }}
                            >
                              Delete
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSolution;
