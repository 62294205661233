import moment from "moment";
import numeral from "numeral";
import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { FilterBox } from "../../../components/components";
import { Footer } from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { RollLoader } from "../../../components/lottie/loader/Loading";
import { Sidebar } from "../../../components/Sidebar";
import { getAPIs } from "../../../functions/api";
import { ToastError } from "../../../functions/helpers";

const ClientList = () => {
    const [clients,setClients] = useState([]);
    const [show,setShow] = useState(true);
    const [members,setMembers] = React.useState([])
    const [loading,setLoading] = React.useState(false)
    const getData = async () => {
        try{
            let res = await getAPIs(`/clients`);
            let clients = res.response_message ? res.response_message :
            [];
            setClients([...clients])
            setShow(false);
        }catch(err){
            setShow(false);
            ToastError(err.msg)
        }
    }
    const getMembers = async (id) => {
        try{
            setLoading(true)
            let res = await getAPIs(`/clients/${id}/team_members`)
            if(res.response_message && Array.isArray(res.response_message)){
                setMembers(res.response_message)
            }
            setLoading(false)
        }catch(err){
            ToastError(err.msg)
        }
    }
    useEffect(()=>{
        getData();
    },[])
    return(
        <div>
            {show ?  <div id="loading">
                <div id="loading-center"></div>
            </div> : null}
        <div className="container-fluid">
           <div className="row">
                 <div className="col-lg-12" >
                    {/* <FilterBox /> */}
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                       <div className="iq-card-header d-flex justify-content-between mt-3">
                            <div className="iq-header-title">
                                <h4 className="card-title">Team Members</h4>
                            </div>
                            <div className="iq-card-header-toolbar d-flex align-items-center">
                                <div className="dropdown">
                                    <span className="dropdown-toggle text-primary" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i className="ri-more-fill"></i>
                                    </span>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton5">
                                    <a className="dropdown-item" href="#"><i className="ri-printer-fill mr-2"></i>Print</a>
                                    <a className="dropdown-item" href="#"><i className="ri-file-download-fill mr-2"></i>Download</a>
                                    </div>
                                </div>
                            </div>
                       </div>
                       <div className="iq-card-body" >
                            <div className="form-group col-sm-4">
                                <label>Filter:</label>
                                <select className="form-control" id="exampleFormControlSelect1"
                                    onChange={(e)=>{
                                        if(e.target.value === "") return
                                        getMembers(e.target.value)
                                    }}
                                >
                                    <option value="">Clients</option>
                                    {
                                        clients && Array.isArray(clients) ? clients.map((item)=>(
                                            <option value={item.userID}>{item.business_name}</option>
                                        ))  : null
                                    }
                                </select>
                                {loading ? <RollLoader /> : null}
                            </div> 
                            <div className="table-responsive">
                                <table className="table mb-0 table-borderless">
                                    <thead>
                                    <tr>
                                        <th scope="col">S/N</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Invitation Status</th>
                                        <th scope="col">Permissions</th>
                                        {/* <th scope="col">Registration Date</th> */}
                                        {/* <th scope="col">Action</th> */}
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            members && Array.isArray(members) && members.map((member,index)=>(
                                                <tr key={index}>
                                                    <td>{`${index + 1}`}</td>
                                                    <td>{member.firstname} {member.lastname}</td>                                       
                                                    <td>{member.invitation_status}</td>
                                                    <td>{member.permissions && member.permissions.join(", ")}</td>
                                                    <td></td>
                                                    {/* <td>{moment(member.registration_date).format("DD MMM, YYYY")}</td> */}
                                                </tr>  
                                            ))
                                        }                                
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                 </div>
                 </div>
           </div>
    </div>
    )
}

export default ClientList;