import React, { useState } from "react";
import { csvToArray } from "../../../../functions/helpers";
import { RollLoader } from "../../../../components/lottie/loader/Loading";
import ReactFileReader from "react-file-reader";
import PageLoader from "../../../../components/PageLoader";
import ResponsiveTable from "../../../../components/ResponsiveTable";
import { useFetchVehicles } from "../../../../functions/queries";
import Button from "../../../../components/Button";
import SampleDoc from "../../../../files/sample_create_bike.csv";
import { useCreateVehicles } from "../../../../functions/mutations";

const CreateBike = () => {
  const [toBeUploaded, setToBeUploaded] = useState([]);

  const { mutate, isLoading: isCreating } = useCreateVehicles();

  const handleBulkUpload = () => {
    mutate({ vehicles: toBeUploaded });
  };

  const handleFiles = (files) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      const data = csvToArray(reader.result);
      setToBeUploaded(data);
    };
    reader.readAsText(files[0]);
  };

  const columns = [
    {
      field: "product_type",
      title: "Product type",
    },
    {
      field: "serial_number",
      title: "Serial number",
    },
    {
      field: "imei",
      title: "Imei number",
    },
    {
      field: "imsi",
      title: "Imsi number",
    },
    {
      field: "product_key",
      title: "Product key",
    },
  ];

  const onClear = (e) => {
    e.preventDefault();
    setToBeUploaded([]);
  };

  const { data: bikes, isLoading } = useFetchVehicles();

  return (
    <div className="container-fluid">
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="row">
          <div className="col-sm-12">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title">Create new e-bike.</h4>
                </div>
              </div>
              <div className="iq-card-body">
                <div id="table" className="table-editable">
                  <div className="m-3">
                    {isCreating ? (
                      <React.Fragment>
                        <RollLoader />
                      </React.Fragment>
                    ) : toBeUploaded && toBeUploaded.length > 0 ? (
                      <React.Fragment>
                        <div>
                          <Button
                            text={"Save Record"}
                            onClick={handleBulkUpload}
                          />
                        </div>
                        <a href="#" onClick={onClear}>
                          Clear uploaded data
                        </a>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <label>Upload a CSV File</label> <br />
                        <ReactFileReader
                          handleFiles={handleFiles}
                          fileTypes={".csv"}
                        >
                          <Button text={"Upload CSV File"} type="submit" />
                        </ReactFileReader>
                        <a
                          href={SampleDoc}
                          target={"_blank"}
                          download={"bikeType.csv"}
                        >
                          Download Sample
                        </a>
                      </React.Fragment>
                    )}
                  </div>
                  {toBeUploaded &&
                  Array.isArray(toBeUploaded) &&
                  toBeUploaded.length > 0 ? (
                    <ResponsiveTable
                      rows={toBeUploaded}
                      columns={columns}
                      total={toBeUploaded.length}
                      limit={toBeUploaded.length}
                    />
                  ) : (
                    <ResponsiveTable rows={bikes} columns={columns} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateBike;
