import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { FilterBox } from "../../components/components";
import { getAPIs, postAPIs } from "../../functions/api";
import {
  confirmAction,
  ToastError,
  ToastSuccess,
} from "../../functions/helpers";
import { RollLoader } from "../../components/lottie/loader/Loading";

const Notifications = () => {
  const [show, setShow] = useState(true);
  const [notifications, setNotification] = useState(null);
  const [data, setData] = useState({
    state: "",
    lcda: "",
    area: "",
    notificationType: "",
    message: "",
  });
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [outside, setOutside] = useState(true);
  const [deleteItem, setDeleteItem] = useState("");
  const [processing, setProcess] = useState(false);
  const actFunction = async (deleteItem) => {
    try {
      let required = ["state", "lcda", "area"];
      setProcess(true);
      let fd = new FormData();
      for (let req of required) {
        fd.append(req, deleteItem[req]);
      }
      await postAPIs("/delete_notification", fd);
      let res = await getAPIs("/get_all_notifications");
      res && res.response_message && setNotification(res.response_message);
      setProcess(false);
      setOutside(true);
      ToastSuccess("Item has been deleted");
    } catch (err) {
      setProcess(false);
      setOutside(true);
      ToastError(err.msg);
    }
  };

  const handleSubmit = async () => {
    let required = ["state", "lcda", "area", "notificationType", "message"];
    try {
      let failed;
      for (let req of required) {
        if (data[req] == "" || data[req].trim() === "") failed = true;
      }
      if (failed) {
        return ToastError("All fields are required");
      }
      setLoading(true);
      let fd = new FormData();
      for (let req of required) {
        fd.append(
          req,
          ["state", "lcda", "area"].includes(req)
            ? data[req].split("__")[0]
            : data[req]
        );
      }
      let url = data.edit ? "/edit_notification" : "/create_notification";
      await postAPIs(url, fd);
      let res = await getAPIs("/get_all_notifications");
      res && res.response_message && setNotification(res.response_message);
      history.go(0);
      setData({
        state: "",
        lcda: "",
        area: "",
        notificationType: "",
        message: "",
      });
      setLoading(false);
      ToastSuccess("Notification has been sent");
    } catch (err) {
      setLoading(false);
      ToastError(err.msg);
    }
  };
  const getData = async () => {
    try {
      let res = await getAPIs("/get_all_notifications");
      res && res.response_message && setNotification(res.response_message);
      setShow(false);
    } catch (err) {
      setShow(false);
      ToastError(err.msg);
    }
  };
  useEffect(() => {
    // getData()
  }, []);
  return (
    <div className="container-fluid">
      {!show ? (
        <div id="loading">
          <div id="loading-center"></div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Create a new notification.</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div id="table" className="table-editable">
                <div
                  className="tab-pane fade active show"
                  id="personal-information"
                  role="tabpanel"
                >
                  <div className="iq-card">
                    <div className="iq-card-body">
                      {/* <div className="row align-items-center"> */}

                      <div className="row">
                        <div className="form-group col-sm-4">
                          <label>Notification Type:</label>
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            value={data.notificationType}
                            onChange={(e) => {
                              setData({
                                ...data,
                                notificationType: e.target.value,
                              });
                            }}
                          >
                            <option selected=""></option>
                            <option selected="Minium Amount">
                              Minium Amount
                            </option>
                            <option selected="No Go Areas">No Go Areas</option>
                            <option selected="Welcome Notification">
                              Welcome Notification
                            </option>
                          </select>
                        </div>
                        <div className="form-group col-sm-4">
                          <label for="fname">Enter Notification Message</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fname"
                            placeholder="Enter Message"
                            onChange={(e) =>
                              setData({ ...data, message: e.target.value })
                            }
                            value={data.message}
                          />
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="text-right">
                      {loading ? (
                        <RollLoader />
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary mr-2"
                          onClick={() => handleSubmit()}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <label> Created Notifications</label>
                <table className="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th>S/No</th>
                      <th>State</th>
                      <th>LCDA</th>
                      <th>Area</th>
                      <th>Notification Type</th>
                      <th>Message</th>
                      <th>Edit Name</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications &&
                      Array.isArray(notifications) &&
                      notifications.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.state}</td>
                          <td>{item.lcda}</td>
                          <td>{item.area}</td>
                          <td>{item.notificationType}</td>
                          <td>{item.message}</td>
                          <td>
                            <a
                              type="submit"
                              className="btn btn-primary mr-2 text-white"
                              onClick={(e) => {
                                e.preventDefault();
                                setData({ ...item, edit: true });
                              }}
                            >
                              Edit
                            </a>
                          </td>
                          <td>
                            {processing && item.message === deleteItem ? (
                              <RollLoader />
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary mr-2 text-white"
                                onClick={() => {
                                  setDeleteItem(item.message);
                                  confirmAction(
                                    outside,
                                    setOutside,
                                    actFunction,
                                    processing,
                                    item.message,
                                    item
                                  );
                                }}
                              >
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
