import { useInfiniteQuery, useQuery } from "react-query";
import { apiFunctions } from "./api";
import {
  GET_ALL_CLIENTS,
  GET_ASSIGNED_BIKES,
  GET_BIKEE_ADMIN_LIST,
  GET_BIKE_PARTS,
  GET_BIKE_TYPES,
  GET_CLIENT,
  GET_CLIENT_ADMIN_LIST,
  GET_COUNTRIES,
  GET_COUNTRY_STATES,
  GET_INDUSTRIES,
  GET_LCDA_AREAS,
  GET_LOGS,
  GET_METRICS,
  GET_FAULT_REPORTS,
  GET_STATE_LCDA,
  GET_UNASSIGNED_BIKES,
  GET_VEHICLES,
  GET_BIKE_LOG,
} from "./query_keys";
import { __flatten, queryResponse } from "./helpers";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const useFetchMetrics = () => {
  return useQuery(GET_METRICS, apiFunctions.get_metrics);
};

export const useFetchClient = (id) => {
  return useQuery([GET_CLIENT, id], () => apiFunctions.get_client(id), {
    enabled: !!id,
  });
};

export const useFetchVehicles = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_vehicles(page),
    queryKey: [GET_VEHICLES, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFectchRealTimeLogs = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_logs(page),
    refetchInterval: 5000,
    queryKey: [GET_LOGS, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchCountries = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_countries(page),
    queryKey: [GET_COUNTRIES, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchCountryStates = (id, num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_country_states(page, id),
    enabled: !!id,
    queryKey: [GET_COUNTRY_STATES, page, id],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchStateLCDAs = (id, num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_state_lcdas(page, id),
    enabled: !!id,
    queryKey: [GET_STATE_LCDA, page, id],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchLcdaAreas = (id, num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_lcda_areas(page, id),
    enabled: !!id,
    queryKey: [GET_LCDA_AREAS, page, id],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchIndustries = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_industries(page),
    queryKey: [GET_INDUSTRIES, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchAllClients = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_all_clients(page),
    queryKey: [GET_ALL_CLIENTS, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchBikeeAdminList = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_bikee_admin_list(page),
    queryKey: [GET_BIKEE_ADMIN_LIST, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchClientAdminList = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_client_admin_list(page),
    queryKey: [GET_CLIENT_ADMIN_LIST, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchBikeTypes = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_bike_types(page),
    queryKey: [GET_BIKE_TYPES, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchAssignedBikes = (id, num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_assigned_bikes(page, id),
    enabled: !!id,
    queryKey: [GET_ASSIGNED_BIKES, page, id],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchUnassignedBikes = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_unassigned_bikes(page),
    queryKey: [GET_UNASSIGNED_BIKES, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchBikeParts = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_bike_parts(page),
    queryKey: [GET_BIKE_PARTS, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchFaultReports = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => apiFunctions.get_fault_reports(page),
    queryKey: [GET_FAULT_REPORTS, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchBikeReports = (id, start_time, end_date, num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () =>
      apiFunctions.get_bike_reports(page, id, start_time, end_date),
    enabled: !!id && !!end_date && !!start_time,
    queryKey: [GET_BIKE_LOG, page, id, start_time, end_date],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};

export const useFetchBikePulseReports = (id, start_time, end_date, num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () =>
      apiFunctions.get_bike_pulse_reports(page, id, start_time, end_date),
    enabled: !!id && !!end_date && !!start_time,
    queryKey: [GET_BIKE_LOG, page, id, start_time, end_date],
  });
  let data = __flatten(query?.data);
  return useMemo(() => {
    return queryResponse(data, query);
  }, [data, query?.isFetching]);
};
