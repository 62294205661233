import React from "react";
import {
  useFetchAllClients,
  useFetchAssignedBikes,
} from "../../../../functions/queries";
import SelectField from "../../../../components/SelectField";
import ResponsiveTable from "../../../../components/ResponsiveTable";
import PageLoader from "../../../../components/PageLoader";
import WarningModal from "../../../../components/WarningModal";
import { useRemoveAssignedBikes } from "../../../../functions/mutations";
import {
  GET_ASSIGNED_BIKES,
  GET_UNASSIGNED_BIKES,
  GET_VEHICLES,
} from "../../../../functions/query_keys";
import { useQueryClient } from "react-query";

const BikeList = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const queryClient = useQueryClient();

  const onClickHandler = (el) => {
    setIsVisible(true);
    setSelected(el);
  };

  const columns = [
    {
      title: "id",
      field: "id",
    },
    {
      title: "Serial number",
      field: "serial_number",
    },
    {
      title: "product key",
      field: "product_key",
    },

    {
      title: "imei",
      field: "imei",
    },
    {
      title: "Status",
      field: "lock_status",
    },
    {
      title: "Last active",
      field: "last_active",
      is_date_time: true,
    },
    {
      title: "Address",
      field: "street_address",
    },

    {
      title: "Battery level",
      field: "battery_charge",
    },
    {
      title: "Action",
      btn_text: "UnAssign",
      is_button: true,
      onClick: onClickHandler,
    },
  ];

  const onCompleteUseRemoveAssignedBikes = () => {
    queryClient.invalidateQueries(GET_VEHICLES);
    queryClient.invalidateQueries(GET_UNASSIGNED_BIKES);
    queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
    setIsVisible(false);
  };

  const { mutate, isLoading: isUnassigning } = useRemoveAssignedBikes(
    onCompleteUseRemoveAssignedBikes
  );

  const { data: clients } = useFetchAllClients();
  const [filter, setFilter] = React.useState("");
  const {
    data: bikes,
    isLoading,
    hasNextPage,
    next_page_num,
    prev_page_num,
    hasPreviousPage,
    page,
    total,
    limit,
  } = useFetchAssignedBikes(filter);

  const onHide = () => {
    setIsVisible(false);
  };

  const onSubmitHandler = () => {
    let fd = {
      client_id: filter,
      serial_num: selected?.serial_number,
    };
    mutate(fd);
  };

  const onChange = (e) => {
    setFilter(e.target.value);
  };

  return (
    <>
      <div className="container-fluid">
        {isLoading ? (
          <PageLoader />
        ) : (
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">List of E-bikes - Full Battery Level Must Be 65535mAh </h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <SelectField
                        options={clients}
                        onChange={onChange}
                        value={filter}
                        placeholder={"Select client"}
                      />
                    </div>
                  </div>
                  <ResponsiveTable
                    columns={columns}
                    rows={bikes}
                    limit={limit}
                    page={page}
                    next_page_num={next_page_num}
                    prev_page_num={prev_page_num}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    total={total}
                  />
                  {isVisible ? (
                    <WarningModal
                      isVisible={isVisible}
                      onHide={onHide}
                      loading={isUnassigning}
                      title={"Unassign Bike?"}
                      sub_text={"Are you sure you want to unassign this bike?"}
                      onClickHandler={onSubmitHandler}
                      canButtonText={"No, go back"}
                      submitButtonText={"Yes, I am sure"}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BikeList;
