import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import {
  confirmAction,
  csvToArray,
  ToastError,
  ToastSuccess,
} from "../../../functions/helpers";
import axios from "axios";
import { getAPIs, postAPIs } from "../../../functions/api";
import { Loading, RollLoader } from "../../../components/lottie/loader/Loading";
import ReactFileReader from "react-file-reader";

const CreateBikeSetup = () => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [bike_types, setBikeTypes] = useState([]);
  const [setups, setBkSetUp] = useState([]);
  const [outside, setOutside] = useState(true);
  const [deleteItem, setDeleteItem] = useState("");
  const [processing, setProcess] = useState(false);
  const [upload, setUpload] = React.useState(false);

  const [data, setData] = useState({
    serviceType: "",
    productType: "",
    description: "",
  });
  const handleSubmit = async (e) => {
    let required = ["serviceType", "productType", "description"];
    try {
      e.preventDefault();
      let failed;
      for (let req of required) {
        if (data[req].trim() === "") failed = true;
      }
      if (failed) {
        return ToastError("All fields are required");
      }
      setLoading(true);
      let fd = new FormData();
      for (let req of required) {
        fd.append(req, data[req]);
      }
      let url = data.edit ? "/edit_ebike_setup" : "/create_ebike_setup";
      let res = await postAPIs(url, fd);
      let res1 = await getAPIs("/get_all_ebike_setup");
      res1 && res1.response_message && setBkSetUp(res1.response_message);
      setData({
        serviceType: "",
        productType: "",
        description: "",
      });
      setLoading(false);
      ToastSuccess("Bike Setup has been saved");
    } catch (err) {
      setLoading(false);
      ToastError(err.msg);
    }
  };
  const getData = async () => {
    try {
      setShow(true);
      let res = await getAPIs("/get_all_ebike_setup");
      let res1 = await getAPIs("/get_all_bikes_types");
      res && res.response_message && setBkSetUp(res.response_message);
      res && res1.response_message && setBikeTypes(res1.response_message);
      setShow(false);
    } catch (err) {
      ToastError(err.msg);
      setShow(false);
    }
  };
  const actFunction = async () => {
    try {
      setProcess(true);
      let deleteItem = localStorage.getItem("delete_item");
      // let fd = new FormData();
      // fd.append('serviceType',deleteItem);
      let fd = {
        serviceType: deleteItem,
      };
      await postAPIs("/delete_ebike_setup", fd);
      let res = await getAPIs("/get_all_ebike_setup");
      res && res.response_message && setBkSetUp(res.response_message);
      setProcess(false);
      setOutside(true);
      ToastSuccess("Item has been deleted");
    } catch (err) {
      setProcess(false);
      setOutside(true);
      ToastError(err.msg);
    }
  };

  const handleBulkUpload = async (data) => {
    try {
      setUpload(true);
      let fd = { setup: data };
      await postAPIs("/bulk_create_ebike_setup", fd);
      let res = await getAPIs("/get_all_ebike_setup");
      res && res.response_message && setBkSetUp(res.response_message);
      ToastSuccess("Bike part has been created");
      setUpload(false);
    } catch (err) {
      setUpload(false);
      ToastError(err.msg);
    }
  };
  const handleFiles = (files) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      // Use reader.result
      const data = csvToArray(reader.result);
      // handleBulkUpload(data);
      setBkSetUp(data);
    };
    reader.readAsText(files[0]);
  };

  useEffect(() => {
    setShow(false);
    //  getData();
  }, []);
  useEffect(() => {}, [setups]);
  return (
    <div className="container-fluid">
      {show ? (
        <div id="loading">
          <div id="loading-center"></div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Create e-bike Setup.</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div id="table" className="table-editable">
                {/* <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                                 <div className="iq-card">                                 
                                    <div className="iq-card-body">
                                       <form onSubmit={handleSubmit}>
                                          <div className="row align-items-center">
                                                   <div className="form-group col-sm-4">
                                                      <label>Select Name of e-bike Setup:</label>
                                                      <select className="form-control" id="exampleFormControlSelect1"
                                                         onChange={(e)=>setData({...data,productType : e.target.value})}
                                                         value={data.productType}
                                                      >
                                                         <option value="">Select type</option>
                                                         {
                                                            bike_types.map((item,index)=>(
                                                               <option value={item.bike_type} key={index}>
                                                                  {item.bike_type}
                                                               </option>
                                                            ))
                                                         }
                                                      </select>
                                          </div>
                                       <div className="form-group col-sm-4">	 
                                       <label for="fname">Type of Service</label>
                                                <input type="text" className="form-control" id="fname" placeholder="Enter Type Service" 
                                                   onChange={(e)=>setData({...data,serviceType : e.target.value})}
                                                   value={data.serviceType}
                                                />
                                       </div>
                                       <div className="form-group col-sm-4">
                                          <label for="fname">Description</label>
                                          <input type="text" className="form-control" id="fname" placeholder="Enter Description" 
                                             onChange={(e)=>setData({...data,description : e.target.value})}
                                             value={data.description}
                                          />
                                       </div>																				 
                                    </div>
                                    {
                                       !loading ? <div className="text-right">
                                          <button type="submit" className="btn btn-primary mr-2">Save</button>
                                       </div> : <div className="pull-right">
                                          <Loading />
                                       </div>
                                    }
                                       </form>
                                    </div>
                                 </div>							  
                              </div> */}

                <div className="m-3">
                  <label>Uploads a CSV File</label> <br />
                  {!upload ? (
                    <ReactFileReader
                      handleFiles={handleFiles}
                      fileTypes={".csv"}
                    >
                      <button type="submit" className="btn btn-primary mr-2">
                        Upload CSV File
                      </button>
                    </ReactFileReader>
                  ) : (
                    <RollLoader />
                  )}
                  <p>
                    <a
                      href="/assets/files/sample_bike_setup.csv"
                      target="_blank"
                    >
                      Download Sample
                    </a>
                  </p>
                </div>
                <table className="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th>S/No</th>
                      <th>Product Type</th>
                      <th>Type of Service</th>
                      <th>Description</th>
                      {/* <th>Edit Name</th>
									            <th>Delete</th> 							    */}
                    </tr>
                  </thead>
                  <tbody>
                    {setups.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.productType}</td>
                        <td>{item.serviceType}</td>
                        <td>{item.description}</td>
                        {/* <td><button type="submit" className="btn btn-primary mr-2 text-white"
                                                   onClick={()=>{
                                                      setData({
                                                         'serviceType': item.service_type,
                                                         'productType': item.product_type,
                                                         'description': item.description,
                                                         edit : true
                                                      })
                                                   }}
                                                >Edit</button></td>
                                                <td>
                                                {
                                                   item.service_type === deleteItem && processing ? (
                                                      <RollLoader />
                                                   ) : (
                                                      <button type="submit" className="btn btn-primary mr-2 text-white"
                                                         onClick={()=>{
                                                            setDeleteItem(item.service_type)
                                                            localStorage.setItem("delete_item",item.service_type)
                                                            confirmAction(outside,setOutside,actFunction,processing,
                                                               item.service_type)
                                                         }}
                                                      >Delete</button>
                                                   )
                                                }
                                             </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBikeSetup;
