import { useState } from "react";
import { useFetchBikeTypes } from "../../../../functions/queries";
import PageLoader from "../../../../components/PageLoader";
import ResponsiveTable from "../../../../components/ResponsiveTable";
import InputField from "../../../../components/InputField";
import Button from "../../../../components/Button";
import {
  useEditBikeType,
  usePostCreateBikeType,
} from "../../../../functions/mutations";
import { RollLoader } from "../../../../components/lottie/loader/Loading";

const CreateBikeType = () => {
  const [data, setData] = useState({
    name: "",
    description: "",
  });

  const onCompleteUsePostCreateBikeType = () => {
    setData({
      name: "",
      description: "",
    });
  };

  const { mutate, isLoading: isCreating } = usePostCreateBikeType(
    onCompleteUsePostCreateBikeType
  );

  const { mutate: edit, isLoading: isEditing } = useEditBikeType(
    onCompleteUsePostCreateBikeType
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fd = {
      name: data.name,
      description: data?.description || "",
      id: data?.id,
    };
    if (fd?.id) {
      return edit(fd);
    }
    mutate(fd);
  };

  const onClickHandler = (el) => {
    setData({
      ...data,
      name: el?.name,
      description: el?.description,
      id: el?.id,
    });
  };

  const columns = [
    {
      field: "name",
      title: "Name",
    },
    {
      title: "Action",
      is_button: true,
      btn_text: "Edit",
      onClick: onClickHandler,
    },
  ];

  const {
    data: types,
    isLoading,
    limit,
    next_page_num,
    prev_page_num,
    hasNextPage,
    hasPreviousPage,
    total,
    page,
  } = useFetchBikeTypes();

  const onChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onClearEntry = (e) => {
    e.preventDefault();
    setData({
      name: "",
      description: "",
    });
  };

  return (
    <div className="container-fluid">
      {isLoading ? <PageLoader /> : null}
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">
                  {data?.id ? `Edit ${data.name}` : "Create a new e-bike name"}
                </h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div id="table" className="table-editable">
                <div
                  className="tab-pane fade active show"
                  id="personal-information"
                  role="tabpanel"
                >
                  <div className="iq-card">
                    <div className="iq-card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="row align-items-center">
                          <div className="form-group col-sm-4">
                            <label>Name</label>
                            <InputField
                              placeholder="Enter name"
                              onChange={onChangeHandler}
                              value={data.name}
                              name={"name"}
                              required={true}
                            />
                          </div>
                          <div className="form-group col-sm-4">
                            <label>Description</label>
                            <InputField
                              placeholder="Enter description"
                              onChange={onChangeHandler}
                              value={data.description}
                              name={"description"}
                            />
                          </div>
                        </div>
                        {data?.id ? (
                          <div className="row">
                            <div className="col-md-8 justify-content-end d-flex">
                              <a href="#" onClick={onClearEntry}>
                                Clear
                              </a>
                            </div>
                          </div>
                        ) : null}
                        <div className="d-flex justify-content-end mt-3">
                          {isCreating || isEditing ? (
                            <RollLoader />
                          ) : (
                            <Button text={"Save"} type="submit" />
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <ResponsiveTable
                limit={limit}
                page={page}
                hasPreviousPage={hasPreviousPage}
                next_page_num={next_page_num}
                prev_page_num={prev_page_num}
                total={total}
                hasNextPage={hasNextPage}
                rows={types}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBikeType;
