import Modal from "react-modal";
import styles from "./styles";
import Button from "../Button";
import { IoMdAlert } from "react-icons/io";
import { RollLoader } from "../lottie/loader/Loading";

const WarningModal = ({
  onClickHandler,
  isVisible,
  onHide,
  title,
  sub_text,
  icon,
  canButtonText,
  submitButtonText,
  loading,
}) => {
  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onHide}
      ariaHideApp={false}
      style={{
        content: styles.content,
        overlay: styles.overlay,
      }}
    >
      <div className="row" style={styles.container}>
        <div className="col-md-12">
          {icon || <IoMdAlert style={styles.icon} size={"2rem"} />}
          <h4>{title}</h4>
          <p>{sub_text}</p>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Button
                text={canButtonText}
                onClick={onHide}
                disabled={loading}
                className={"btn"}
                style={styles.cancel_button}
              />
            </div>
            {loading ? (
              <RollLoader />
            ) : (
              <Button
                text={submitButtonText}
                onClick={onClickHandler}
                disabled={loading}
                loading={loading}
                style={styles.submit_btn}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WarningModal;
