import React from "react"

export const Footer = () => {
    return(
            <footer className="bg-white iq-footer">
            <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 text-right">
                    Copyright 2021 POWERED BY MOTION MOBILITY LIMITED All Rights Reserved.
                </div>
            </div>
            </div>
        </footer>
    )
}