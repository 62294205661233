import React, { useState } from "react";
import { ToastError, ToastSuccess } from "../../../../functions/helpers";
import { RollLoader } from "../../../../components/lottie/loader/Loading";
import {
  useFetchAllClients,
  useFetchClient,
  useFetchUnassignedBikes,
} from "../../../../functions/queries";
import ResponsiveTable from "../../../../components/ResponsiveTable";
import PageLoader from "../../../../components/PageLoader";
import SelectField from "../../../../components/SelectField";
import Button from "../../../../components/Button";
import {
  usePostAssignVehicles,
  //usePostLockBattery,
  usePostLockBike,
  //usePostUnLockBattery,
  usePostUnLockBike,
} from "../../../../functions/mutations";
import { useDispatch, useSelector } from "react-redux";
import { onToggleSwitch } from "../../../../components/Redux/ConfigSlice";

const AssignBikes = () => {
  const [selections, setSelections] = React.useState([]);
  const [clientID, setClientID] = useState("");
  const dispatch = useDispatch();
  const switchItems = useSelector((state) => state?.Config?.switchItems);

  const onCheckHandler = (item) => {
    if (item?.id && selections.includes(item?.id)) {
      let arr = [...selections];
      arr.splice(arr.indexOf(item?.id), 1);
      return setSelections(arr);
    }
    if (item?.id) setSelections([...selections, item?.id]);
  };

  const onCompleteUsePostUnlockBattery = () => {
    ToastSuccess("Update has been saved");
    dispatch(onToggleSwitch([]));
  };

  const onError = () => {
    dispatch(onToggleSwitch([]));
  };

  const { mutate: lock } = usePostLockBike(
    onCompleteUsePostUnlockBattery,
    onError
  );

  const { mutate: unlock } = usePostUnLockBike(
    onCompleteUsePostUnlockBattery,
    onError
  );

  // const { mutate: lockBattery } = usePostLockBattery(
  //   onCompleteUsePostUnlockBattery
  // );

  // const { mutate: unlockBattery } = usePostUnLockBattery(
  //   onCompleteUsePostUnlockBattery
  // );

  const onToggleBikeLock = (type, bike) => {
    if (switchItems.includes(`${type}${bike?.id}`)) {
      let arr = [...switchItems];
      arr.splice(arr.indexOf(`${type}${bike?.id}`), 1);
      unlock(bike?.serial_number);
      return dispatch(onToggleSwitch(arr));
    }
    let arr = [...switchItems, `${type}${bike?.id}`];
    lock(bike?.serial_number);
    dispatch(onToggleSwitch(arr));
  };

  const columns = [
    {
      title: "Checkbox",
      is_checkbox: true,
      onCheck: onCheckHandler,
    },
    {
      title: "Product type",
      field: "product_type",
    },
    {
      title: "Imei",
      field: "imei",
    },
    {
      title: "searial number",
      field: "serial_number",
    },
    {
      title: "Address",
      field: "street_address",
    },
    {
      title: "Last active",
      field: "last_active",
      is_date_time: true,
    },
    {
      title: "Status",
      field: "lock_status",
    },
    {
      title: "Battery Level",
      field: "battery_charge",
    },
    {
      title: "Lock / Unlock bike",
      onToggle: onToggleBikeLock,
      type: "bike",
      field: "lock_status",
      is_switch: true,
    },
    // {
    //   title: "Lock / Unlock battery",
    //   onToggle: onToggleBatteryLock,
    //   field: "status",
    //   type: "battery",
    //   is_switch: true,
    // },
  ];

  const { data: client } = useFetchClient(clientID);

  const { data: clients } = useFetchAllClients();

  const {
    data: bikes,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    page,
    next_page_num,
    prev_page_num,
    total,
    limit,
  } = useFetchUnassignedBikes();

  const onCompleteUsePostAssignVehicles = () => {
    setClientID("");
    setSelections([]);
    ToastSuccess("Record has been saved");
  };

  const { mutate, isLoading: isAssigning } = usePostAssignVehicles(
    onCompleteUsePostAssignVehicles
  );

  const onChangeHandler = (e) => {
    setClientID(e.target.value);
  };

  const handleSubmit = () => {
    if (!selections.length || !clientID || !client?.data?.area_id)
      return ToastError("Please select a client name and at least a bike");
    const payload = {
      vehicle_id: selections,
      area_id: client?.data?.area_id,
      client_id: clientID,
    };
    mutate(payload);
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="row">
          <div className="col-sm-12">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title">Assign E-bikes to client - Full Battery Level Must Be 65535mAh. </h4>
                </div>
              </div>
              <div className="iq-card-body">
                <div className="col-md-4">
                  <SelectField
                    options={clients}
                    value={clientID}
                    onChange={onChangeHandler}
                    placeholder={"Select client"}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  {isAssigning ? (
                    <RollLoader />
                  ) : (
                    <Button text={"Assign Bikes"} onClick={handleSubmit} />
                  )}
                </div>
                <div className="d-flex justify-content-end mb-5 mt-1">
                  <p>
                    {selections.length} bike{selections.length > 1 ? "s" : ""}{" "}
                    selected
                  </p>
                </div>
                <ResponsiveTable
                  rows={bikes}
                  columns={columns}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  page={page}
                  next_page_num={next_page_num}
                  prev_page_num={prev_page_num}
                  limit={limit}
                  total={total}
                  selections={selections}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignBikes;
