import axios from "axios";
import React, { useEffect } from "react";
import { Footer } from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { RollLoader } from "../../../components/lottie/loader/Loading";
import { Sidebar } from "../../../components/Sidebar";
import { BASE_URL, postAPIs } from "../../../functions/api";
import { ToastError } from "../../../functions/helpers";

const AddTeam = () => {
  const [data, setData] = React.useState({
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
    age: "",
    gender: "",
    street_address_1: "",
    street_address_2: "",
    department_name: "",
    country: "",
    state: "",
    user_role: "",
    phone_number: "",
    alternate_contact: "",
    email: "",
    mobile_app_passcode: "",
    town: "",
    username: "",
    permissions: "",
  });
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [lgas, setLGAs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState("");
  const handleSubmit = async () => {
    try {
      let required = [
        "firstname",
        "lastname",
        "email",
        "phone_number",
        "age",
        "gender",
        "street_address_1",
        "street_address_2",
        "department_name",
        "country",
        "state",
        "user_role",
        "phone_number",
        "alternate_contact",
        "email",
        "mobile_app_passcode",
        "town",
        "username",
        "permissions",
      ];
      let failed = false;
      for (let req of required) {
        if (!data[req] || data[req].toString().trim() === "") {
          failed = true;
        }
      }
      if (failed) return ToastError("All fields are required");
      setLoading(true);
      let res = await postAPIs("/clients", data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      ToastError(err.msg);
    }
  };
  const fetchLocation = async (type, param) => {
    try {
      const base = BASE_URL;
      if (type === "countries") {
        //setData({...data,state : "",area : ""})
        let res = await axios.get(`${base}countries`);
        return setCountries(res.data.results);
      }
      setFetching(type);
      if (type === "states") {
        let res = await axios.get(`${base}countries/${param}/states`);
        setStates(res.data.results);
        return setFetching("");
      }
      // setData({...data,area : ""})
      let res = await axios.get(`${base}states/${param}/lgas`);
      setLGAs(res.data.results);
      return setFetching("");
    } catch (err) {
      ToastError(err.msg);
    }
  };
  useEffect(() => {
    fetchLocation("countries");
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-3">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Add New Team Member</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <form>
                <div className="form-group">
                  <div className="add-img-user profile-img-edit">
                    <img
                      className="profile-pic img-fluid"
                      src="/assets/images/user/profile.png"
                      alt="profile-pic"
                    />
                    <div className="p-image">
                      <a
                        href="javascript:void();"
                        className="upload-button btn iq-bg-primary"
                      >
                        File Upload
                      </a>
                      <input
                        className="file-upload"
                        type="file"
                        accept="image/*"
                      />
                    </div>
                  </div>
                  <div className="img-extension mt-3">
                    <div className="d-inline-block align-items-center">
                      <span>Only</span>
                      <a href="javascript:void();">.jpg</a>
                      <a href="javascript:void();">.png</a>
                      <a href="javascript:void();">.jpeg</a>
                      <span>allowed</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>User Role:</label>
                  <select
                    className="form-control"
                    id="selectuserrole"
                    value={data.permissions}
                    onChange={(e) =>
                      setData({ ...data, permissions: e.target.value })
                    }
                  >
                    <option value="">Select</option>
                    <option value="rider">Rider</option>
                    <option value="administrator">Administrator</option>
                    <option value="logistic">Logistic</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">New User Information</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div className="new-user-info">
                <form>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="fname">First Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fname"
                        placeholder="First Name"
                        value={data.firstname}
                        onChange={(e) =>
                          setData({ ...data, firstname: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="lname">Last Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lname"
                        placeholder="Last Name"
                        value={data.lastname}
                        onChange={(e) =>
                          setData({ ...data, lastname: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="lname">Age:</label>
                      <input
                        type="number"
                        className="form-control"
                        id="lname"
                        placeholder="Age"
                        value={data.age}
                        onChange={(e) =>
                          setData({ ...data, age: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="lname">Gender:</label>
                      <select
                        className="form-control"
                        value={data.age}
                        onChange={(e) =>
                          setData({ ...data, gender: e.target.value })
                        }
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label for="add1">Street Address 1:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="add1"
                        placeholder="Street Address 1"
                        value={data.street_address_1}
                        onChange={(e) =>
                          setData({ ...data, street_address_1: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="add2">Street Address 2:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="add2"
                        placeholder="Street Address 2"
                        value={data.street_address_1}
                        onChange={(e) =>
                          setData({ ...data, street_address_2: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label for="cname">Department Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cname"
                        placeholder="Department Name"
                        value={data.department_name}
                        onChange={(e) =>
                          setData({ ...data, department_name: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group col-sm-12">
                      <label>Country:</label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={data.country}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setStates([]);
                            return setData({ ...data, state: "" });
                          }
                          setData({
                            ...data,
                            country: e.target.value,
                            state: "",
                          });
                          let index = countries.findIndex(
                            (item) => item.country === e.target.value
                          );
                          let selected = countries[index];
                          fetchLocation("states", selected.id);
                        }}
                      >
                        <option value="">Select country</option>
                        {countries &&
                          Array.isArray(countries) &&
                          countries.map((item, index) => (
                            <option value={item.country} key={index}>
                              {" "}
                              {item.country}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group col-sm-12">
                      <label>
                        State:{" "}
                        {fetching === "states" ? (
                          <span className="fa fa-spinner fa-spin"></span>
                        ) : null}
                      </label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect2"
                        value={data.state}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            return setData({ ...data, state: e.target.value });
                          }
                          setData({ ...data, state: e.target.value, area: "" });
                          let index = states.findIndex(
                            (item) => item.state === e.target.value
                          );
                          let selected = states[index];
                          fetchLocation("areas", selected.id);
                        }}
                      >
                        <option value="">Select</option>
                        {states && Array.isArray(states)
                          ? states.map((item, index) => (
                              <option value={item.state} key={index}>
                                {item.state}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label for="mobno">Mobile Number:</label>
                      <input
                        type="number"
                        className="form-control"
                        id="mobno"
                        placeholder="Mobile Number"
                        value={data.phone_number}
                        onChange={(e) =>
                          setData({ ...data, phone_number: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="altconno">Alternate Contact:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="altconno"
                        placeholder="Alternate Contact"
                        value={data.alternate_contact}
                        onChange={(e) =>
                          setData({
                            ...data,
                            alternate_contact: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="email">Email:</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        value={data.email}
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="pno">Mobile App PassCode:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="pno"
                        placeholder="Pin Code"
                        value={data.mobile_app_passcode}
                        onChange={(e) =>
                          setData({
                            ...data,
                            mobile_app_passcode: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label for="city">Town/City:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="Town/City"
                        value={data.town}
                        onChange={(e) =>
                          setData({ ...data, town: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <hr />
                  {!loading ? (
                    <button
                      type="button"
                      className="btn btn-primary mr-2"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </button>
                  ) : (
                    <RollLoader />
                  )}
                  {/* <h5 className="mb-3">Security</h5>
                                 <div className="row">
                                    <div className="form-group col-md-12">
                                       <label for="uname">User Name:</label>
                                       <input type="text" className="form-control" id="uname" placeholder="User Name" />
                                    </div>
                                    <div className="form-group col-md-6">
                                       <label for="pass">Password:</label>
                                       <input type="password" className="form-control" id="pass" placeholder="Password" />
                                    </div>
                                    <div className="form-group col-md-6">
                                       <label for="rpass">Repeat Password:</label>
                                       <input type="password" className="form-control" id="rpass" placeholder="Repeat Password" />
                                    </div>
                                 </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTeam;
