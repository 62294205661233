import React, { useState } from "react";
import { csvToArray } from "../../../../functions/helpers";
import { RollLoader } from "../../../../components/lottie/loader/Loading";
import ReactFileReader from "react-file-reader";
import { useFetchBikeParts } from "../../../../functions/queries";
import PageLoader from "../../../../components/PageLoader";
import ResponsiveTable from "../../../../components/ResponsiveTable";
import { useCreateBikeParts } from "../../../../functions/mutations";
import Button from "../../../../components/Button";

const CreateBikeParts = () => {
  const [toBeUploaded, setToBeUploaded] = useState([]);

  const handleBulkUpload = () => {
    mutate({
      parts: toBeUploaded,
    });
  };
  const handleFiles = (files) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      const data = csvToArray(reader.result);
      setToBeUploaded(data);
    };
    reader.readAsText(files[0]);
  };

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Bike Type",
      field: "bike_type",
    },
    {
      title: "Created Date",
      field: "created_at",
      is_date: true,
    },
  ];

  const onClear = (e) => {
    e.preventDefault();
    setToBeUploaded([]);
  };

  const { mutate, isLoading } = useCreateBikeParts();

  const {
    data: parts,
    isFetching,
    hasNextPage,
    hasPreviousPage,
    next_page_num,
    prev_page_num,
    page,
    total,
    limit,
  } = useFetchBikeParts();

  return (
    <div className="container-fluid">
      {isFetching ? <PageLoader /> : null}
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Create e-bike parts.</h4>
              </div>
            </div>
            <div className="iq-card-body">
              {isLoading ? (
                <RollLoader />
              ) : toBeUploaded &&
                Array.isArray(toBeUploaded) &&
                toBeUploaded.length > 0 ? (
                <React.Fragment>
                  <div className="m-3">
                    <Button text={"Save Record"} onClick={handleBulkUpload} />
                    <div>
                      <a href={"#"} onClick={onClear}>
                        Clear uploaded data
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="m-3">
                    <label>Upload a CSV File</label> <br />
                    <ReactFileReader
                      handleFiles={handleFiles}
                      fileTypes={".csv"}
                    >
                      <button type="submit" className="btn btn-primary mr-2">
                        Upload CSV File
                      </button>
                    </ReactFileReader>
                    <a
                      href={
                        "https://bikee-storage.s3.amazonaws.com/documents/FtclCWsMfkWIjU00uCD89K7uIRb5GrZRSmokxBkA.csv"
                      }
                      target={"_blank"}
                      download={"sample_bikeType.csv"}
                    >
                      Download Sample
                    </a>
                  </div>
                </React.Fragment>
              )}
              {toBeUploaded &&
              Array.isArray(toBeUploaded) &&
              toBeUploaded.length > 0 ? (
                <ResponsiveTable
                  rows={toBeUploaded}
                  total={toBeUploaded.length}
                  limit={toBeUploaded.length}
                  columns={columns}
                />
              ) : (
                <ResponsiveTable
                  rows={parts}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  next_page_num={next_page_num}
                  prev_page_num={prev_page_num}
                  total={total}
                  limit={limit}
                  columns={columns}
                  page={page}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBikeParts;
