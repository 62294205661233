import React, { useState } from "react";
import { useHistory } from "react-router";
import { ToastError, validateFormEntries } from "../../../../functions/helpers";
import { RollLoader } from "../../../../components/lottie/loader/Loading";
import {
  useFetchCountries,
  useFetchCountryStates,
  useFetchIndustries,
  useFetchLcdaAreas,
  useFetchStateLCDAs,
} from "../../../../functions/queries";
import InputField from "../../../../components/InputField";
import SelectField from "../../../../components/SelectField";
import Button from "../../../../components/Button";
import { usePostRegister } from "../../../../functions/mutations";

const CreateAdmin = () => {
  const history = useHistory();
  const [admin, setAdmin] = useState({
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    address: "",
    name: "",
    type: "admin",
    password: "",
    password_confirmation: "",
    status: "",
    area_id: "",
    lcda_id: "",
    name: "",
    country_id: "",
    state_id: "",
    industry_id: "",
  });

  const { data: industries } = useFetchIndustries();

  const { data: countries } = useFetchCountries();

  const { data: states } = useFetchCountryStates(admin?.country_id);

  const { data: lcdas } = useFetchStateLCDAs(admin?.state_id);

  const { data: areas } = useFetchLcdaAreas(admin?.lcda_id);

  const onCompleteUsePostRegister = () => {
    history.push("/admins");
  };

  const { mutate, isLoading } = usePostRegister(onCompleteUsePostRegister);

  const onChangeHandler = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let resp = validateFormEntries(admin, [
      "email",
      "firstname",
      "lastname",
      "name",
      "address",
      "country_id",
      "state_id",
      "lcda_id",
      "area_id",
      "industry_id",
      "phone",
    ]);
    if (!resp?.status) return ToastError(resp?.msg);
    mutate({
      ...admin,
      first_name: admin?.firstname,
      last_name: admin?.lastname,
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Create admin user</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div id="table" className="table-editable">
                <div
                  className="tab-pane fade active show"
                  id="personal-information"
                  role="tabpanel"
                >
                  <div className="iq-card">
                    <div className="iq-card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="row align-items-center">
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="Name">Name</label>
                            <InputField
                              name="name"
                              placeholder="Enter name"
                              value={admin.name}
                              // required={true}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="fname">First Name</label>
                            <InputField
                              name="firstname"
                              placeholder="Enter first name"
                              // required={true}
                              value={admin.firstname}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="lastname">Last Name</label>
                            <InputField
                              name="lastname"
                              placeholder="Enter last name"
                              value={admin.lastname}
                              // required={true}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="email">Enter Email</label>
                            <InputField
                              name="email"
                              type={"email"}
                              placeholder="Enter email address"
                              value={admin.email}
                              // required={true}
                              onChange={onChangeHandler}
                            />
                          </div>
                          {/* <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="email">Enter Password</label>
                            <InputField
                              name="password"
                              type={"password"}
                              // required={true}
                              placeholder="Enter password"
                              value={admin.password}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="email">Confirm Password</label>
                            <InputField
                              name="password_confirmation"
                              type={"password"}
                              // required={true}
                              placeholder="Confirm password"
                              value={admin.password_confirmation}
                              onChange={onChangeHandler}
                            />
                          </div> */}
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="phone">Phone Number</label>
                            <InputField
                              name="phone"
                              type={"number"}
                              placeholder="Enter phone number"
                              value={admin.phone}
                              // required={true}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="position">Position</label>
                            <InputField
                              name="position"
                              placeholder="Enter position"
                              value={admin.position}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="industry">Industry </label>
                            <SelectField
                              placeholder={"Select industry"}
                              options={industries}
                              name={"industry_id"}
                              // required={true}
                              value={admin?.industry_id}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="Address">Address</label>
                            <InputField
                              name="address"
                              placeholder="Enter address"
                              value={admin.address}
                              // required={true}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="country">Country</label>
                            <SelectField
                              placeholder={"Select country"}
                              options={countries}
                              name={"country_id"}
                              // required={true}
                              value={admin?.country_id}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="state">State</label>
                            <SelectField
                              placeholder={"Select state"}
                              options={states}
                              name={"state_id"}
                              // required={true}
                              value={admin?.state_id}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="lcda">Lcda</label>
                            <SelectField
                              placeholder={"Select LCDA"}
                              options={lcdas}
                              name={"lcda_id"}
                              // required={true}
                              value={admin?.lcda_id}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="area">Area</label>
                            <SelectField
                              placeholder={"Select area"}
                              options={areas}
                              name={"area_id"}
                              // required={true}
                              value={admin?.area_id}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <div className="form-group col-sm-6 col-md-4">
                            <label htmlFor="status">Status</label>
                            <SelectField
                              placeholder={"Select status"}
                              options={["ACTIVE", "INACTIVE"]}
                              name={"status"}
                              // required={true}
                              value={admin?.status}
                              onChange={onChangeHandler}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          {!isLoading ? (
                            <Button type="submit" text={"Create"} />
                          ) : (
                            <RollLoader />
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAdmin;
