import React from "react";
import { useFetchBikeeAdminList } from "../../../../functions/queries";
import PageLoader from "../../../../components/PageLoader";
import ResponsiveTable from "../../../../components/ResponsiveTable";

const AdminList = () => {
  const {
    isLoading,
    data: admins,
    next_page_num,
    hasNextPage,
    total,
    limit,
    page,
    hasPreviousPage,
    prev_page_num,
  } = useFetchBikeeAdminList();

  const columns = [
    {
      title: "First Name",
      field: "first_name",
    },
    {
      title: "Last Name",
      field: "last_name",
    },
    {
      title: "Phone Number",
      field: "phone",
    },

    {
      title: "Date Created",
      field: "created_at",
      is_date: true,
    },
  ];

  return (
    <div>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-header d-flex justify-content-between mt-3">
                  <div className="iq-header-title">
                    <h4 className="card-title">List all admin users</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <ResponsiveTable
                    rows={admins}
                    hasPreviousPage={hasPreviousPage}
                    page={page}
                    next_page_num={next_page_num}
                    prev_page_num={prev_page_num}
                    columns={columns}
                    total={total}
                    limit={limit}
                    hasNextPage={hasNextPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminList;
