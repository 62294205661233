import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import {
  confirmAction,
  ToastError,
  ToastSuccess,
} from "../../../functions/helpers";
import axios from "axios";
import { getAPIs, postAPIs } from "../../../functions/api";
import numeral from "numeral";
import { Loading, RollLoader } from "../../../components/lottie/loader/Loading";

const CreateSolutionCategory = () => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [data, setData] = useState({
    solution_name: "",
  });
  const [outside, setOutside] = useState(true);
  const [deleteItem, setDeleteItem] = useState("");
  const [processing, setProcess] = useState(false);
  const [old, setOld] = useState(null);
  const handleSubmit = async (e) => {
    let required = ["solution_name"];
    try {
      e.preventDefault();
      let failed;
      for (let req of required) {
        if (data[req].trim() === "") failed = true;
      }
      if (failed) {
        return ToastError("All fields are required");
      }
      setLoading(true);
      let fd = new FormData();
      for (let req of required) {
        fd.append(req, data[req]);
      }
      let url = data.edit ? "/edit_solution_type" : "/create_solution_type";
      if (data.edit) {
        fd = new FormData();
        fd.append("old_solution_name", old.name);
        fd.append("new_solution_name", data.solution_name);
      }
      await postAPIs(url, fd);
      let res = await getAPIs("/get_all_solution_types");
      res && res.response_message && setTypes(res.response_message);
      setData({
        solution_name: "",
      });
      setLoading(false);
      ToastSuccess("Solution category has been saved");
    } catch (err) {
      setLoading(false);
      ToastError(err.msg);
    }
  };
  const getData = async () => {
    try {
      let res = await getAPIs("/get_all_solution_types");
      res && res.response_message && setTypes(res.response_message);
      setShow(false);
    } catch (err) {
      setShow(false);
      ToastError(err.msg);
    }
  };
  const actFunction = async () => {
    try {
      return;
      setProcess(true);
      let deleteItem = localStorage.getItem("delete_item");
      let fd = new FormData();
      fd.append("solution_name", deleteItem);
      await postAPIs("/delete_solution_type", fd);
      let res = await getAPIs("/get_all_solution_types");
      res && res.response_message && setTypes(res.response_message);
      setProcess(false);
      setOutside(true);
      ToastSuccess("Item has been deleted");
    } catch (err) {
      setProcess(false);
      setOutside(true);
      ToastError(err.msg);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div class="container-fluid">
      {show ? (
        <div id="loading">
          <div id="loading-center"></div>
        </div>
      ) : null}
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-header d-flex justify-content-between">
              <div class="iq-header-title">
                <h4 class="card-title">
                  Create Client Rental E-Bike Solution Category
                </h4>
              </div>
            </div>
            <div class="iq-card-body">
              <div id="table" class="table-editable">
                <div
                  class="tab-pane fade active show"
                  id="personal-information"
                  role="tabpanel"
                >
                  <div class="iq-card">
                    <div class="iq-card-body">
                      <form onSubmit={handleSubmit}>
                        <div class="row align-items-center">
                          <div class="form-group col-sm-4">
                            <label>
                              Create a new e-bike solution category:
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="fname"
                              placeholder="Enter New Solution Type"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  solution_name: e.target.value,
                                });
                              }}
                              value={data.solution_name}
                            />
                          </div>
                        </div>
                        {loading ? (
                          <div className="pull-right">
                            <Loading />
                          </div>
                        ) : (
                          <div className="text-right">
                            <button type="submit" class="btn btn-primary mr-2">
                              {data.edit ? "Edit" : "Save"}
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h4 class="card-title">View Category type</h4>
            </div>
          </div>
          <table class="table table-bordered table-responsive-md table-striped text-center">
            <thead>
              <tr>
                <th>S/No</th>
                <th>Solution Category</th>
                <th colspan="2">Action</th>
              </tr>
            </thead>
            <tbody>
              {types.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>
                    <button
                      type="submit"
                      class="btn btn-primary mr-2"
                      onClick={() => {
                        setOld({
                          name: item.name,
                        });
                        setData({
                          solution_name: item.name,
                          edit: true,
                        });
                      }}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    {processing && deleteItem === item.name ? (
                      <RollLoader />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary mr-2 text-white"
                        onClick={() => {
                          setDeleteItem(item.name);
                          localStorage.setItem("delete_item", item.name);
                          confirmAction(
                            outside,
                            setOutside,
                            actFunction,
                            processing,
                            item.name
                          );
                        }}
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CreateSolutionCategory;
