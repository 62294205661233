import React from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/main/Dashboard";
import NewSignUps from "./pages/enterprise/users/NewSignUps";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import "./App.css";
import RealTime from "./pages/enterprise/clients/Realtime";
import AddClient from "./pages/enterprise/clients/AddClient";
import AreaList from "./pages/enterprise/clients/AreaList";
import CreateArea from "./pages/enterprise/clients/CreateArea";
import CreateBikeSetup from "./pages/enterprise/ebikes/CreateBikeSetup";
import CreateBike from "./pages/enterprise/ebikes/CreateBike";
import CreateBikeType from "./pages/enterprise/ebikes/CreateBikeType";
import BikeList from "./pages/enterprise/ebikes/BikeList";
import AssignBikes from "./pages/enterprise/ebikes/AssignBikes";
import CreateBikeParts from "./pages/enterprise/ebikes/CreateBikeParts";
import CreateSolutionCategory from "./pages/enterprise/ebikes/CreateSolutionCategory";
import SolutionList from "./pages/enterprise/ebikes/SolutionList";
import CreateSolutionPlan from "./pages/enterprise/ebikes/CreatePlan";
import RentalPaymentType from "./pages/enterprise/ebikes/RentalPaymentType";
import CreateAdmin from "./pages/enterprise/users/CreateAdmin";
import ClientAdmins from "./pages/enterprise/users/ClientAdmins";
import DailyUsers from "./pages/enterprise/users/DailyUsers";
import UnassignBikes from "./pages/enterprise/ebikes/UnassignedEbikes";
import SharingActiveUsers from "./pages/enterprise/users/SharingActiveUsers";
import ClientActiveUsers from "./pages/enterprise/users/ClientActiveUsers";
import SharingRiderSignups from "./pages/enterprise/users/SharingRiderSignUps";
import ClientRiderSignups from "./pages/enterprise/users/ClientRiderSignups";
import AddTeam from "./pages/enterprise/users/AddTeam";
import Notifications from "./pages/enterprise/Notifications";
import Reports from "./pages/enterprise/users/Reports";
import Plans from "./pages/enterprise/finance/Plans";
import Payments from "./pages/enterprise/finance/Payments";
import Coupons from "./pages/enterprise/finance/Coupons";
import CreateCoupons from "./pages/enterprise/finance/CreateCoupons";
import CreateSolution from "./pages/enterprise/ebikes/CreateSolution";
import TeamList from "./pages/enterprise/users/TeamList";
import ClientList from "./pages/enterprise/clients/ClientList";
import AdminList from "./pages/enterprise/users/AdminList";
import GeoReport from "./pages/enterprise/users/GeoReports";
import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFound from "./pages/auth/NotFound";
import { Provider } from "react-redux";
import store from "./components/Redux";
import HeatMap from "./pages/enterprise/ebikes/HeatMap";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: "tracked",
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={client}>
        <NotificationContainer />
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <ProtectedRoute exact path="/realtime" component={RealTime} />
            <ProtectedRoute exact path="/add_client" component={AddClient} />
            <ProtectedRoute
              exact
              path="/edit_client/:id"
              component={AddClient}
            />

            <ProtectedRoute exact path="/clients" component={ClientList} />
            <ProtectedRoute exact path="/areas_list" component={AreaList} />
            <ProtectedRoute exact path="/create_area" component={CreateArea} />
            <ProtectedRoute
              exact
              path="/create_bike_type"
              component={CreateBikeType}
            />
            <ProtectedRoute exact path="/bike_list" component={BikeList} />
            <ProtectedRoute
              exact
              path="/assign_bikes"
              component={AssignBikes}
            />
            <ProtectedRoute
              exact
              path="/assign_bikes/:id"
              component={AssignBikes}
            />

            <ProtectedRoute exact path="/create_bike" component={CreateBike} />
            <ProtectedRoute
              exact
              path="/create_parts"
              component={CreateBikeParts}
            />
            <ProtectedRoute
              exact
              path="/solution_category"
              component={CreateSolutionCategory}
            />
            <ProtectedRoute
              exact
              path="/solution_list"
              component={SolutionList}
            />
            <ProtectedRoute
              exact
              path="/solution_plan"
              component={CreateSolutionPlan}
            />
            <ProtectedRoute
              exact
              path="/rental_payment_type"
              component={RentalPaymentType}
            />
            <ProtectedRoute
              exact
              path="/create_users"
              component={CreateAdmin}
            />
            <ProtectedRoute
              exact
              path="/create_solution"
              component={CreateSolution}
            />
            <ProtectedRoute exact path="/users" component={ClientAdmins} />
            <ProtectedRoute exact path="/admins" component={AdminList} />
            <ProtectedRoute exact path="/new_signups" component={NewSignUps} />
            <ProtectedRoute exact path="/daily_users" component={DailyUsers} />
            <ProtectedRoute
              exact
              path="/unassigned_bikes"
              component={UnassignBikes}
            />
            <ProtectedRoute
              exact
              path="/sharing_active_users"
              component={SharingActiveUsers}
            />
            <ProtectedRoute
              exact
              path="/client_active_users"
              component={ClientActiveUsers}
            />
            <ProtectedRoute
              exact
              path="/sharing_sign_ups"
              component={SharingRiderSignups}
            />
            <ProtectedRoute
              exact
              path="/client_sign_ups"
              component={ClientRiderSignups}
            />
            <ProtectedRoute exact path="/add_team" component={AddTeam} />
            <ProtectedRoute exact path="/teams" component={TeamList} />
            <ProtectedRoute
              exact
              path="/notifications"
              component={Notifications}
            />
            <ProtectedRoute exact path="/heat_map" component={HeatMap} />
            <ProtectedRoute exact path="/reports" component={Reports} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute exact path="/plans" component={Plans} />
            <ProtectedRoute exact path="/payments" component={Payments} />
            <ProtectedRoute exact path="/coupons" component={Coupons} />
            <ProtectedRoute
              exact
              path="/setup_coupon"
              component={CreateCoupons}
            />
            <ProtectedRoute exact path="/geo-reports" component={GeoReport} />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Router>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
