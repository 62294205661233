import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

export const ToastSuccess = (msg) => {
  return NotificationManager.success(msg);
};

export const ToastError = (msg) => {
  return NotificationManager.error(msg);
};
export const Capitalize = (string) => {
  string = string.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
  return string;
};
export const confirmAction = (
  outside,
  setOutside,
  actFunction,
  processing,
  name,
  param
) => {
  return confirmAlert({
    childrenElement: () => <div />,
    customUI: ({ onClose }) => (
      <div class="react-confirm-alert">
        <div class="react-confirm-alert-body">
          <h1>Confirm to delete</h1>
          Are you sure to delete '{name}'.
          <div></div>
          <div class="react-confirm-alert-button-group">
            <button
              onClick={async () => {
                setOutside(false);
                actFunction(param);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    ),
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  });
};

export const csvToArray = (str, delimiter = ",") => {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str
    .slice(0, str.indexOf("\n"))
    .split(delimiter)
    .map((item) => item.trim());

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows
    .map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index]
          ? values[index].trim().toString()
          : undefined;
        return object;
      }, {});
      return el;
    })
    .filter((item) => item && item[headers[headers.length - 1]] !== undefined);

  // return the array
  return arr;
};

export const __flatten = (res) => {
  return (
    res?.data?.data ||
    res?.assigned_vehicles ||
    res?.vehicles ||
    res?.unassigned_vehicles ||
    res?.data ||
    undefined
  );
};

export const getNextPageParam = (lastPage, allPages) => {
  const page = JSON.parse(localStorage.getItem("pg_num")) || 1;
  let nextPageParam =
    allPages
      ?.filter((el) => el?.data?.current_page === page)?.[0]
      ?.data?.next_page_url?.match("page=[0-9]")?.[0]
      ?.split("page=")?.[1] || undefined;
  return nextPageParam;
};

export const getPreviousPageParam = (firstPage, allPages) => {
  const page = JSON.parse(localStorage.getItem("pg_num")) || 1;
  let prevPageParam =
    allPages
      ?.filter((el) => el?.data?.current_page === page)?.[0]
      ?.data?.prev_page_url?.match("page=[0-9]")?.[0]
      ?.split("page=")?.[1] || undefined;
  return prevPageParam;
};

export const queryResponse = (data, query) => {
  return {
    data,
    isLoading: query?.isLoading,
    isFetching: query?.isFetching,
    hasNextPage:
      !!query?.data?.data?.next_page_url || !!query?.data?.next_page_number,
    fetchNextPage: query.fetchNextPage,
    hasPreviousPage:
      !!query?.data?.data?.prev_page_url || !!query?.data?.previous_page_number,
    fetchPrevPage: query.fetchPreviousPage,
    total: query?.data?.data?.total || query?.data?.total || data?.length || 0,
    page: query?.data?.data?.current_page || query?.data?.current_page_number,
    prev_page_num:
      query?.data?.data?.prev_page_url
        ?.match("page=[0-9]")?.[0]
        ?.split("page=")?.[1] ||
      query?.data?.previous_page_number ||
      1,
    next_page_num:
      query?.data?.data?.next_page_url
        ?.match("page=[0-9]")?.[0]
        ?.split("page=")?.[1] ||
      query?.data?.next_page_number ||
      1,
    limit: query?.data?.data?.per_page || 10,
    isFetchNextPage: query.isFetchingNextPage,
  };
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateFormEntries = (obj, required) => {
  let response = {
    status: true,
  };
  let msg = "";
  for (let req of required) {
    if (!obj?.[req] || obj?.[req]?.trim() === "") {
      msg = `${req ? Capitalize(req?.replaceAll("_", " ")) : ""} is required`;
      break;
    }
  }
  if (msg) {
    response = {
      msg,
      status: false,
    };
  }
  return response;
};
