import React from "react";
import { Footer } from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";

const ClientActiveUsers = () => {
    return(
        <div className="container-fluid">
           
			<div className="row">
			   
				  <div className="col-lg-8" >
                     <div className="iq-card iq-card-block iq-card-stretch iq-card-height" style={{width: "1200px"}}>
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Client's Daily Active Riders</h4>
                           </div>
						   <form>
                                       <div className="row align-items-center">
									   <div className="form-group col-sm-4">
                                             <label>Select Client:</label>
                                             <select className="form-control" id="exampleFormControlSelect1">
                                                <option selected="">JISE TECHNOLOGY LIMITED</option>
                                                <option>KFC</option>
												<option>CHICKEN REPUBLIC</option>
                                             </select>
											 </div> 
									    <div className="form-group col-sm-4">
                                             <label>Select State:</label>
                                             <select className="form-control" id="exampleFormControlSelect1">
                                                <option selected="">Lagos</option>
                                                <option>Abuja</option>
												<option>Oyo</option>
                                             </select>
											 </div> 
                                            <div className="form-group col-sm-4">
                                             <label>Select LCDA:</label>
                                             <select className="form-control" id="exampleFormControlSelect1">
                                                <option selected="">Ikeja</option>
                                                <option>Ajah</option>
												<option>Maryland</option>
                                             </select>
											 </div>
											 <div className="form-group col-sm-4">
                                             <label>Select Area:</label>
                                             <select className="form-control" id="exampleFormControlSelect1">
                                                <option selected="">Agege</option>
                                                <option>Ogba</option>
												<option>Magodo</option>
                                             </select>
											 </div>
											 <div className="form-group col-sm-4">
                                              <button type="submit" className="btn btn-primary mr-2">Submit</button>											 
											 </div>
                                       </div>
                                    </form>
                           <div className="iq-card-header-toolbar d-flex align-items-center">
                              <div className="dropdown">
                                 <span className="dropdown-toggle text-primary" id="dropdownMenuButton5" data-toggle="dropdown">
                                 <i className="ri-more-fill"></i>
                                 </span>
                                 <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton5">
                                    <a className="dropdown-item" href="#"><i className="ri-printer-fill mr-2"></i>Print</a>
                                    <a className="dropdown-item" href="#"><i className="ri-file-download-fill mr-2"></i>Download</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="iq-card-body" >
						<div className="iq-card calender-small">
                        <div className="col-md-3">
                           <input type="text" className="flatpicker d-none" />
                        </div>
                     </div>
                           <div className="table-responsive">
                              <table className="table mb-0 table-borderless">
                                 <thead>
                                    <tr>
                                       <th scope="col">S/N</th>
                                       <th scope="col">Date Registered</th>
									   <th scope="col">Full Name</th>
                                       <th scope="col">Phone Number</th>
                                       <th scope="col">Client Name</th>
                                       <th scope="col">Status</th>
									   <th scope="col">Last Active Time</th>									   
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td>1</td>
									   <td>20/02/2020</td>                                       
                                       <td> <a href="users profile page.html">Toloulope</a></td>
									   <td>+2349093440570</td>
                                       <td>JISE TECHNOLOGY LIMITED</td>
									   <td>Active (Riding)</td>
                                       <td>13:03:34, 20.06.2021</td>
                                    </tr>                                  
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
				  </div>
            </div>
    )
}

export default ClientActiveUsers;