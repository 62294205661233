import AppColors from "../../functions/AppColors";

const styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    borderRadius: "0.5rem",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(55 35 35 / 75%)",
    zIndex: 1000,
  },
  cancel_button: {
    background: `linear-gradient(to right, ${AppColors.gray} 0%,  ${AppColors.gray})`,
    color: AppColors.white,
    paddingLeft: "1rem",
    paddingRight: "1rem",
    borderRadius: "2rem",
  },
  submit_btn: {
    borderRadius: "2rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  container: {
    padding: "2rem",
  },
  icon: {
    color: AppColors.red,
    marginBottom: "2rem",
  },
};
export default styles;
