import React from "react";

import { useFetchFaultReports } from "../../../../functions/queries";
import PageLoader from "../../../../components/PageLoader";
import ResponsiveTable from "../../../../components/ResponsiveTable";
import ReportModal from "../../../../components/ReportModal";

const Reports = () => {
  const [modal, setModal] = React.useState(false);
  const [selected, setSelected] = React.useState({});

  const onClickHandler = (item) => {
    setSelected(item);
    setModal(true);
  };

  const onHide = () => {
    setModal(false);
  };

  const columns = [
    {
      title: "Date",
      field: "created_at",
      is_date_time: true,
    },
    {
      title: "Description",
      field: "description",
    },
    {
      title: "Details",
      is_button: true,
      btn_text: "View",
      onClick: onClickHandler,
    },
  ];

  const {
    data: reports,
    page,
    next_page_num,
    hasNextPage,
    hasPreviousPage,
    prev_page_num,
    isLoading,
    limit,
    total,
  } = useFetchFaultReports();

  return (
    <div className="container-fluid">
      {isLoading ? <PageLoader /> : null}
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">All Reports</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <ResponsiveTable
                columns={columns}
                rows={reports}
                page={page}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                next_page_num={next_page_num}
                prev_page_num={prev_page_num}
                limit={limit}
                total={total}
              />
            </div>
          </div>
        </div>
        {modal ? (
          <ReportModal isVisible={modal} onHide={onHide} report={selected} />
        ) : null}
      </div>
    </div>
  );
};

export default Reports;
