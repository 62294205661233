import axios from "axios";

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const BASE_URL =
  "https://motion-fleets-ci-cd-letp5hkt7a-uc.a.run.app/api/";
const IOT_BASE_URL = process.env.REACT_APP_IOT_BASE_URL;
const API_KEY = process.env.REACT_APP_IOT_API_KEY;

export const apiFunctions = {
  get_metrics: () => {
    return getAPIs(`/enterprise-metrics`);
  },
  get_vehicles: (page = 1) => {
    return getAPIs(`/get-vehicles/?page=${page}`, true);
  },
  lock_bike: (bike_sn) => {
    return postAPIs(`/lock-vehicle/${bike_sn}`, {}, true);
  },
  unlock_bike: (bike_sn) => {
    return postAPIs(`/unlock-vehicle/${bike_sn}`, {}, true);
  },
  lock_battery: (bike_sn) => {
    return postAPIs(`/lock-battery/${bike_sn}`, {}, true);
  },
  unlock_battery: (bike_sn) => {
    return postAPIs(`/unlock-battery/${bike_sn}`, {}, true);
  },
  get_logs: (page = 1) => {
    return getAPIs(`/logs/?page=${page}`);
  },
  get_industries: async (page) => getAPIs(`/industries/?page=${page}`),
  get_countries: async (page) => getAPIs(`/countries/?page=${page}`),
  get_country_states: async (page, id) =>
    getAPIs(`/country-states/${id}/?page=${page}`),
  get_state_lcdas: async (page, id) => {
    return getAPIs(`/state-lcdas/${id}/?page=${page}`);
  },
  get_lcda_areas: async (page, id) => {
    return getAPIs(`/lcda-areas/${id}/?page=${page}`);
  },
  register: (fd) => {
    return postAPIs(`/register/`, fd);
  },
  get_client: (id) => {
    return getAPIs(`/clients/${id}/`);
  },
  update_client_info: (fd) => {
    return putAPIs(`/clients/${fd?.id}/`, fd);
  },
  get_all_clients: (page = 1) => {
    return getAPIs(`/clients/?page=${page}`);
  },
  get_bikee_admin_list: (page = 1) => {
    return getAPIs(`/super-users/?page=${page}`);
  },
  get_client_admin_list: (page = 1) => {
    return getAPIs(`/clients-admin/?page=${page}`);
  },
  get_bike_types: (page = 1) => {
    return getAPIs(`/bikes/?page=${page}`);
  },
  create_bike_type: (fd) => {
    return postAPIs(`/bikes/`, fd);
  },
  edit_bike_type: (fd) => {
    return putAPIs(`/bikes/${fd.id}/`, fd);
  },
  get_assigned_bikes: (page, id) => {
    return getAPIs(`/get-assigned/${id}?page=${page}`, true);
  },
  get_unassigned_bikes: (page) => {
    return getAPIs(`/get-unassigned/?page=${page}`, true);
  },
  create_vehicles: (fd) => {
    return postAPIs(`/register-vehicles/`, fd, true);
  },
  assign_bikes: (fd) => {
    return postAPIs(`/bikes-to-client/`, fd);
  },
  get_bike_parts: (page = 1) => {
    return getAPIs(`/bike-parts/?page=${page}`);
  },
  create_bike_parts: (fd) => {
    return postAPIs(`/bike-parts`, fd);
  },
  get_fault_reports: (page) => {
    return getAPIs(`/reports/?page=${page}`);
  },
  get_bike_reports: (page, bike_sn, start_date, end_date) => {
    return getAPIs(
      `/get-reports/${bike_sn}/${start_date}/${end_date}/${page}`,
      true
    );
  },
  get_bike_pulse_reports: (page, bike_sn, start_date, end_date) => {
    return getAPIs(
      `/get-pulse-reports/${bike_sn}/${start_date}/${end_date}/${page}`,
      true
    );
  },
  set_alarm: async (serial_num) => {
    return postAPIs(`/set-alarm/${serial_num}`, {}, true);
  },
  remove_alarm: async (serial_num) => {
    return postAPIs(`/remove-alarm/${serial_num}`, {}, true);
  },
  remove_assigned_bike: async (fd) => {
    return postAPIs(
      `/remove-assigned/${fd?.client_id}/${fd?.serial_num}`,
      {},
      true
    );
  },
};

export const getAPIs = async (path, isIOT) => {
  let token = localStorage.getItem("token");
  const url = isIOT ? IOT_BASE_URL : baseUrl;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Accept: "application/json",
          Pragma: "no-cache",
          Expires: 0,
          "x-api-key": API_KEY,
        },
      })
      .then((result) => {
        resolve(result?.data);
      })
      .catch((error) => {
        let code = error.code;
        let msg = error.message;
        if (error.message === "Request failed with status code 401") {
          localStorage.clear();
          window.location.href = "/login";
        }
        reject({ status: code, msg: msg });
      });
  });
};

export const postAPIs = async (path, payload, isIOT) => {
  let token = localStorage.getItem("token");
  const url = isIOT ? IOT_BASE_URL : baseUrl;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}${path}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Accept: "application/json",
          Pragma: "no-cache",
          "x-api-key": API_KEY,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        let code = error.code;
        let msg =
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
            ? error?.response?.data?.message
            : error?.response?.data?.message?.[0]
            ? error?.response?.data?.message?.[0]
            : error.message;
        if (error.message === "Request failed with status code 401") {
          localStorage.clear();
          window.location.href = "/login";
        }
        reject({ status: code, msg: msg });
      });
  });
};
export const postApi = async (route, payload) => {
  let token = localStorage.getItem("token");
  try {
    const user = await fetch(`${baseUrl}${route}`, {
      method: "POST",
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    return await user.json();
  } catch (err) {
    return err;
  }
};

export const putAPIs = async (path, payload) => {
  let token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}${path}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        let code = error.code;
        let msg =
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
            ? error?.response?.data?.message
            : error?.response?.data?.message?.[0]
            ? error?.response?.data?.message?.[0]
            : error.message;
        if (error.message === "Request failed with status code 401") {
          localStorage.clear();
          window.location.href = "/login";
        }
        reject({ status: code, msg: msg });
      });
  });
};

export const putApi = async (route, payload) => {
  let token = localStorage.getItem("token");
  try {
    const user = await fetch(`${baseUrl}${route}`, {
      method: "PUT",
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    return await user.json();
  } catch (err) {
    return err;
  }
};
