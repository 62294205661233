import React, { useState } from "react"
import { useHistory } from "react-router";
import { Header } from "../../../components/Header"
import { Sidebar } from "../../../components/Sidebar"
import { ToastError } from "../../../functions/helpers";
import axios from "axios";

const Plans = () => {
    let history = useHistory();
    const [user,setUser] = useState({
        "email": "",
        "password": "",
        "returnSecureToken": true
    });
    const [loading,setLoading] = useState(false);
    const handleSubmit = async (e) => {
        let required = ["email","password"]
        try{
            e.preventDefault();
            let failed;
            for(let req of required){
                if(user[req].trim() === "") failed = true
            }
            if(failed){
                return ToastError("Please provided both email and password");
            }
            setLoading(true)
            let res = await axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${process.env.REACT_APP_FIREBASE_WEB_API_KEY}`,
            user);
            let data = {
                'firstname': res.firstname,
                'lastname':"Admin",
                'email':"devadmin@gmail.com",
                'position':"developer",
                'role':"superadmin"
            }  
            localStorage.setItem("token",res.data.idToken);
            localStorage.setItem("user",res.data);
            return history.push("/dashboard");
        }catch(err){
            setLoading(false);
            ToastError("Invalid login details")
        }
    }
    return(
        <div className="container-fluid">
           
        <div className="row">
              <div className="col-sm-12">
              <div className="iq-card-header d-flex justify-content-between">
        <div className="iq-header-title">
            <h4 className="card-title">View payment plans</h4>
        </div>
    </div>
                <div className="mb-2">
                    <label>Uploas a CSV File</label>			                               
                    <button type="submit" className="btn btn-primary mr-2 ml-2">Download CSV File</button>	
                </div>					  
                <table className="table table-bordered table-responsive table-striped text-center">
                    <thead>
                    <tr>
                        <th>S/No</th>
                        <th>Product Type</th>
                        <th>Plan Type</th>
                        <th>State</th>
                        <th>LCDA</th>
                        <th>Area</th>
                        <th>Rider Pause Amount</th>   
                        <th>Minimum Unlock Amount NGN</th>
                        <th>Amount (per minute)</th>                                      
                        <th colspan="3">Action</th>
                                                        
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>2</td>									  
                        <td>Boost</td>
                        <td>Daily Sharing</td>
                        <td>Lagos</td>
                        <td>Ikeja</td>
                        <td>Magodo</td>
                        <td>NGN500.00</td>
                        <td>NGN500.00</td>
                        <td>NGN20.00</td>
                        <td>
                            <a type="submit" className="btn btn-primary mr-2">Edit</a>
                        </td>
                        <td>
                            <a type="submit" href="#" className="btn btn-success mr-2">Coupon</a>
                        </td>
                        <td>
                            <a type="submit" className="btn btn-danger mr-2">Delete</a>
                        </td>								   							   
                    </tr>
                    </tbody>
                </table>
                 <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                       <div className="iq-header-title">
                          <h4 className="card-title">Create new/edit payment plan</h4>
                       </div>
                    </div>
                    <div className="iq-card-body">
                       <div id="table" className="table-editable">
                        <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                            <div className="iq-card">                                 
                                <div className="iq-card-body">
                                    <form>
                                        <div className="row align-items-center">
                                            <div className="form-group col-sm-4">
                                                <label>Select Name of e-bike:</label>
                                                <select className="form-control" id="exampleFormControlSelect1">
                                                    <option selected="">Boost</option>
                                                    <option>Moov</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label>Select Plan Solution:</label>
                                                <select className="form-control" id="exampleFormControlSelect1">
                                                    <option selected="">Daily Sharing</option>
                                                    <option>Next Day Sharing</option>
                                                    <option>Weekly Rent</option>
                                                    <option>Monthly Rent</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label>Select State:</label>
                                                <select className="form-control" id="exampleFormControlSelect1">
                                                    <option selected="">Lagos</option>
                                                    <option>Abuja</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label>Select LCDA:</label>
                                                <select className="form-control" id="exampleFormControlSelect1">
                                                    <option selected="">Ikeja</option>
                                                    <option>Lekki/Epe</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <label>Select Area:</label>
                                                <select className="form-control" id="exampleFormControlSelect1">
                                                    <option selected="">Magodo</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-sm-4">	 
                                            <label for="fname">Create Minimum Amount</label>
                                        <input type="text" className="form-control" id="fname" placeholder="Enter Minimum Amount" />
                                        </div>
                                        <div className="form-group col-sm-4">	 
                                            <label for="fname">Rider Pause Amount</label>
                                        <input type="text" className="form-control" id="fname" placeholder="Enter Pause Amount" />
                                        </div>
                                            <div className="form-group col-sm-4">	 
                                            <label for="fname">Create Amount</label>
                                        <input type="text" className="form-control" id="fname" placeholder="Enter Amount" />
                                        </div>						 
                                    </div>
                                </form>
                            </div>
                            <button type="submit" className="btn btn-primary mr-2 text-white">Save</button>
                            <button type="submit" className="btn btn-secondary mr-2 text-white">Edit</button>
                          </div>							  
                       </div>						   
                            
                          </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
    )
}

export default Plans;