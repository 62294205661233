import React from "react";
import Lottie from "lottie-react";
import LoaderIcon from "./loader.json";
import Roll from "./roll.json";

export const Loading = () => {
  const style = {
    display: "flex",
    width: "15%",
  };

  return <Lottie animationData={LoaderIcon} style={style} />;
};

export const RollLoader = ({ width }) => {
  const style = {
    display: "flex",
    width: width || "10%",
  };
  return <Lottie animationData={Roll} style={style} />;
};
